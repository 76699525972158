import React from 'react'
import axios from 'axios';
import {Button, Grid, Paper} from '@material-ui/core';

function EventTypeDeleter(props) {
    let type = props.selectedType;

    function deleteAssignment() {
        axios.post(global.localip + '/deleteType/', {
            type: type,
        }).then(function () {
            props.fetchTypes();
            props.setView("start");
        })
    }


    return (
        <div>
            <Grid container>
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                    <Paper style={{ padding: "10px" }}>
                        <div >
                            <h3>Willst du diese Zuweisung wirklich entfernen?</h3>
                            <h4>Name: {type.type_name}</h4>
                            <Button variant="outlined" onClick={deleteAssignment}>Entfernen</Button>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={1}></Grid>
            </Grid>
        </div>
    )
}

export default EventTypeDeleter
