import { Button, Paper, Grid, TextField } from '@material-ui/core';
import { Face, Fingerprint } from '@material-ui/icons';

import React from 'react'

function Login(props) {
    let userid = props.userid;

    function login() {
        if (document.getElementById("email").value === "" || document.getElementById("password").value === "") {
            alert("Bitte wähle Passwort und E-Mail");
        } else {
            let url = global.localip + '/loginUser/' + document.getElementById("email").value + "/" + document.getElementById("password").value;
            fetch(url)
                .then(response => response.json())
                .then(result2 => {
                    if (result2.length === 1) {
                        if (result2[0].error !== undefined) {
                            if (result2[0].error === 0) {
                                alert("Falsches Password")
                            } else if (result2[0].error === 1) {
                                alert("Email nicht bekannt")
                            }
                        } else {
                            localStorage.setItem('userid', result2[0].id);
                            localStorage.setItem('rank', result2[0].rank);
                            localStorage.setItem('loginstamp', new Date());
                            userid = result2[0].id;

                            let url = global.localip + '/getUserSettings/' + userid;
                            fetch(url)
                                .then(response => response.json())
                                .then(result => {
                                    //console.log(result);
                                    props.setDefaultView(result[0].view);
                                    props.setValue(result[0].view);
                                    props.setLoggedIn(true);
                                    props.setShow0(result[0].display_0);
                                    props.setShow1(result[0].display_1);
                                    props.setShow2(result[0].display_2);
                                    props.setShow5(result[0].display_5);
                                    props.setRank(result[0].rank);
                                    props.setPermittedViews(JSON.parse(result[0].permitted_views));
                                    props.setBlocked(false);
                                    setTimeout(() => {
                                        props.setValue(result[0].view);
                                    }, 10);
                                })
                        }
                    }
                })
        }
    }

    return (
        <Paper style={{ margin: "10%" }}>
            <form onSubmit={(e) => { e.preventDefault() }}>
                <div style={{ padding: "1%" }}>
                    <h3>Bitte logge dich ein, um fortzufahren</h3>
                    <Grid container spacing={8} alignItems="flex-end">
                        <Grid item>
                            <Face />
                        </Grid>
                        <Grid item md={true} sm={true} xs={true}>
                            <TextField id="email" label="Email" type="email" fullWidth autoFocus required autoComplete="on" />
                        </Grid>
                    </Grid>
                    <Grid container spacing={8} alignItems="flex-end">
                        <Grid item>
                            <Fingerprint />
                        </Grid>
                        <Grid item md={true} sm={true} xs={true}>
                            <TextField id="password" label="Passwort" type="password" fullWidth required autoComplete="on" />
                        </Grid>
                    </Grid>
                    <br></br>
                    <br></br>
                    <br></br>
                    <Grid container justify="center" style={{ marginTop: '10px' }}>
                        <Button variant="outlined" type="submit" color="primary" style={{ textTransform: "none" }} onClick={() => {
                            login();
                        }}>Login</Button>
                    </Grid>
                </div>
            </form>
        </Paper>
    );
}



export default Login;