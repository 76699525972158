import { MenuItem, Select } from '@material-ui/core';
import React from 'react'

function StatisiticsQuickTimeSelector(props) {
    const [select, setSelect] = React.useState(0);

    let quartals = props.quartals;
    let years = props.years;

    //console.log(years);
    return (
        <div>
            {/* <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={select}
                onChange={(e) => { setSelect(e.target.value) }}
            >
                <MenuItem value={0}>Wählen</MenuItem>
                <MenuItem value={1}>Jahre</MenuItem>
                <MenuItem value={2}>Quartale</MenuItem>
            </Select>
            {select == 1 ?
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={0}
                    onChange={(e) => {

                    }}
                >
                    <MenuItem value={0}>Wählen</MenuItem>
                    {quartals.map(function (quartal) {
                        
                    })}
                </Select> : ""} */}
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={select}
                onChange={(e) => { 
                    setSelect(e.target.value);
                    if(e.target.value !== 0){
                        let startdate = new Date();
                        startdate.setFullYear(e.target.value);
                        startdate.setMonth(0);
                        startdate.setDate(1);
                        startdate.setHours(0);
                        startdate.setMinutes(0);
                        startdate.setSeconds(0);
    
                        let enddate = new Date();
                        enddate.setFullYear(e.target.value);
                        enddate.setMonth(11);
                        enddate.setDate(31);
                        enddate.setHours(23);
                        enddate.setMinutes(59);
                        enddate.setSeconds(59);
    
                        props.setStartDate(startdate);
                        props.setEndDate(enddate);
                    }
                }}
            >
                <MenuItem value={0}>Schnellauswahl</MenuItem>
                    {years.map(function (year) {
                        return <MenuItem value={year}>{year}</MenuItem>;
                    })}
            </Select>
        </div>
    )
}

export default StatisiticsQuickTimeSelector
