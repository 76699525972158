import React from 'react'
import { Grid, IconButton, List, ListSubheader } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import TemplateListItem from './TemplateListItem';
import Tooltip from '@material-ui/core/Tooltip';

function TemplateList(props) {
    return (
        <List
            subheader={<ListSubheader>
                <Grid container justify="flex-end">
                    <Tooltip title="Kopieren">
                        <IconButton edge="end" onClick={() => {
                            props.setView("create");
                        }}>
                            <Add></Add>
                        </IconButton>
                    </Tooltip>
                </Grid>
            </ListSubheader>}>
            {props.templates.map(function (template, i) {
                return <TemplateListItem template={template} key={i} setListSelect={props.setListSelect} setView={props.setView}></TemplateListItem>
            })}
        </List>
    )
}

export default TemplateList
