import React from 'react'
import { CircularProgress } from '@material-ui/core';
import MaterialTable from 'material-table'
import { forwardRef } from 'react';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};


function MailManager() {
  const [mails, setMails] = React.useState(undefined);
  const [events, setEvents] = React.useState(undefined);

  function loadEvents() {
    let url = global.localip + '/getAllData';
    fetch(url)
      .then(response => response.json())
      .then(result => {
        setEvents(result);
      })
  }

  if (events == undefined) {
    loadEvents();
    return <CircularProgress></CircularProgress>
  }


  if (mails === undefined) {
    fetchMails();
    return <CircularProgress></CircularProgress>
  }


  function fetchMails() {
    let url = global.localip + '/getMails';
    fetch(url)
      .then(response => response.json())
      .then(result => {
        setMails(result);
      })
  }

  function EventName(props) {
    for (let i = 0; i < events.length; i++) {
      const event = events[i];
      if(event.event_id === props.event_id){
        return event.event_name;
      }
    }
    return null;
  }


  return (
    <div>
      <MaterialTable
        icons={tableIcons}
        title="Mailübersicht"
        columns={[
          { title: 'Empfänger', field: 'mail_to' },
          { title: 'Status', field: 'status', lookup: { 0: 'Ausstehend', 1: 'Erfolgreich', 2: "Fehlgeschlagen" } },
          { title: 'Aufgetretener Fehler', field: 'status_error' },
          { title: 'Schulung', field: 'event_id', render: rowData => <EventName event_id={rowData.event_id}></EventName> },
        ]}
        data={mails}
        options={{
          search: true
        }}
      />
    </div>
  )
}

export default MailManager
