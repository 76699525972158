import React from 'react'
import {Chip, ListItem, ListItemText} from '@material-ui/core';

function MoveListItem(props) {
    var booking = props.booking;
    const [seats, setSeats] = React.useState(0);
    const [fetched, setFetched] = React.useState(false);
    if (fetched === false) {
      let url = global.localip + '/getBookingbyEventId/' + props.event.event_id;
      fetch(url)
        .then(response => response.json())
        .then(result2 => {
          var seatsp = 0;
          for (let i = 0; i < result2.length; i++) {
            const element = result2[i];
            seatsp = seatsp + element.booking_seats;
          }
          setFetched(true);
          setSeats(seatsp);
        })
    }
  
  
    function handleListItemClick(eventid) {
      //console.log(eventid);
      let url = global.localip + '/changeEventID/' + booking.booking_id + "/" + eventid + "/0";
      props.setOpen(false);
      fetch(url)
        .then(response => response.json())
        .then(result => {
          window.location.reload();
        })
    }
  
    return (
      <ListItem button onClick={() => handleListItemClick(props.event.event_id)} key={props.event.event_id}>
        <ListItemText primary={props.event.event_name} />
        <Chip label={seats + " / " + props.event.event_seats + " Plätze gebucht"}></Chip>
      </ListItem>
    )
  }

export default MoveListItem
