import React from "react";
import "./App.css";

import { AppBar, CircularProgress } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Settings from "./Settings/Settings";
import Login from "./Login/Login";
import EventList from "./Events/EventList";
import EventFake100000 from "./Events/EventFake100000";
import Calendar from "./Calendar/Calendar";
import ManageUsers from "./ManageUsers/ManageUsers";
import LogOutButton from "./General/LogOutButton";
import StatisticsManager from "./Statistics/StatisticsManager";
import EventTypeManager from "./EventType/EventTypeManager";
import Templates from "./Templates/Templates";

var axios = require("axios");

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      key={value}
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

global.localip = "https://" + window.location.hostname + ":4006";
global.localip = "https://stue.graphisoft-nord.de:4006";
//global.localip = "159.69.45.240"

function App() {
  const [userid, setUserId] = React.useState(undefined);
  const [allevents, setAllEvents] = React.useState(undefined);
  const [rank, setRank] = React.useState(undefined);
  const [blocked, setBlocked] = React.useState(true);
  const [loggedin, setLoggedIn] = React.useState(false);
  const [value, setValue] = React.useState(10);
  const [defaultview, setDefaultView] = React.useState(undefined);
  const [show0, setShow0] = React.useState(false);
  const [show1, setShow1] = React.useState(true);
  const [show2, setShow2] = React.useState(false);
  const [show5, setShow5] = React.useState(false);
  const [permitted_views, setPermittedViews] = React.useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (!loggedin) {
    const LoginComponent = (
      <Login
        setShow0={setShow0}
        setShow1={setShow1}
        setShow2={setShow2}
        setShow5={setShow5}
        setLoggedIn={setLoggedIn}
        setValue={setValue}
        setDefaultView={setDefaultView}
        userid={userid}
        setRank={setRank}
        rank={rank}
        setPermittedViews={setPermittedViews}
        setBlocked={setBlocked}
      ></Login>
    );

    if (localStorage.getItem("userid") != undefined) {
      if (userid == undefined) {
        setUserId(localStorage.getItem("userid"));
        return LoginComponent;
      }

      if (defaultview === undefined) {
        let url = global.localip + "/getUserSettings/" + userid;
        fetch(url)
          .then((response) => response.json())
          .then((result) => {
            //console.log(result);

            if (
              new Date(localStorage.getItem("loginstamp")).getDate() ==
              new Date().getDate()
            ) {
              setLoggedIn(true);
            }
            setDefaultView(result[0].view);
            setShow0(result[0].display_0);
            setShow1(result[0].display_1);
            setShow2(result[0].display_2);
            setShow5(result[0].display_5);
            setValue(result[0].view);
            setRank(result[0].rank);
            setPermittedViews(JSON.parse(result[0].permitted_views));
            setBlocked(false);
            setTimeout(() => {
              setValue(result[0].view);
            }, 10);
            // setTimeout(() => {
            //   setValue(result[0].view);
            // }, 500);
          });
      }
    }
    return LoginComponent;
  }

  if (value !== 10 && !permitted_views.includes(value)) {
    setValue(10);
  }

  if (blocked) {
    return <CircularProgress></CircularProgress>;
  }

  return (
    <div>
      {/*<div style={{ backgroundImage: `url(${background})`, minHeight: "100%"}}>*/}
      <AppBar position="static" color="transparent">
        <div
          style={{
            minWidth: "100%",
            minHeight: "100%",
            backgroundColor: "#979797",
            color: "white",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            indicatorColor="primary"
          >
            {permitted_views.includes(0) ? (
              <Tab value={0} label="Schulungen" />
            ) : null}
            {permitted_views.includes(1) ? (
              <Tab value={1} label="Archiv" />
            ) : null}
            {permitted_views.includes(2) ? (
              <Tab value={2} label="Warteliste" />
            ) : null}
            {permitted_views.includes(3) ? (
              <Tab value={3} label="Kalender" />
            ) : null}
            {permitted_views.includes(4) ? (
              <Tab value={4} label="Vorlagen" />
            ) : null}
            {permitted_views.includes(5) ? (
              <Tab value={5} label="Statistik" />
            ) : null}
            {permitted_views.includes(6) ? (
              <Tab value={6} label="Kurstypen" />
            ) : null}
            <Tab label="Einstellungen" value={10} />
            {(permitted_views.includes(7) || rank == 2) && !blocked ? (
              <ManageUsers rank={2}></ManageUsers>
            ) : null}
            <LogOutButton right="10px" top="-5px"></LogOutButton>
          </Tabs>
        </div>
      </AppBar>
      {permitted_views.length === 0 ? (
        <TabPanel value={value} index={value}>
          Keine Ansichten freigeschaltet. Bitte wende dich an einen
          Administrator.
        </TabPanel>
      ) : null}
      {permitted_views.includes(0) ? (
        <TabPanel value={value} index={0}>
          {blocked ? null : (
            <EventList
              allevents={allevents}
              rank={rank}
              setAllEvents={setAllEvents}
              userid={userid}
              show0={show0}
              show1={show1}
              show2={show2}
              show5={show5}
            ></EventList>
          )}
        </TabPanel>
      ) : null}
      {permitted_views.includes(1) ? (
        <TabPanel value={value} index={1}>
          {blocked ? null : (
            <EventList
              allevents={allevents}
              rank={rank}
              setAllEvents={setAllEvents}
              userid={userid}
              old
              show0={show0}
              show1={show1}
              show2={show2}
              show5={show5}
            ></EventList>
          )}
        </TabPanel>
      ) : null}
      {permitted_views.includes(2) ? (
        <TabPanel value={value} index={2}>
          {blocked ? null : (
            <EventFake100000
              allevents={allevents}
              rank={rank}
              setAllEvents={setAllEvents}
              userid={userid}
            ></EventFake100000>
          )}
        </TabPanel>
      ) : null}
      {permitted_views.includes(3) ? (
        <TabPanel value={value} index={3}>
          {blocked ? null : (
            <Calendar
              allevents={allevents}
              rank={rank}
              setAllEvents={setAllEvents}
              userid={userid}
              show0={show0}
              show1={show1}
              show2={show2}
              show5={show5}
            ></Calendar>
          )}
        </TabPanel>
      ) : null}
      {permitted_views.includes(4) ? (
        <TabPanel value={value} index={4}>
          {blocked ? null : <Templates userid={userid}></Templates>}
        </TabPanel>
      ) : null}
      {permitted_views.includes(5) ? (
        <TabPanel value={value} index={5}>
          {blocked ? null : (
            <StatisticsManager userid={userid} rank={rank}></StatisticsManager>
          )}
        </TabPanel>
      ) : null}
      {permitted_views.includes(6) ? (
        <TabPanel value={value} index={6}>
          {blocked ? null : (
            <EventTypeManager userid={userid} rank={rank}></EventTypeManager>
          )}
        </TabPanel>
      ) : null}
      <TabPanel value={value} index={10}>
        {blocked ? null : (
          <Settings
            permitted_views={permitted_views}
            userid={userid}
            defaultview={defaultview}
            setDefaultView={setDefaultView}
            show0={show0}
            show1={show1}
            show2={show2}
            show5={show5}
            setShow0={setShow0}
            setShow1={setShow1}
            setShow2={setShow2}
            setShow5={setShow5}
          ></Settings>
        )}
      </TabPanel>
    </div>
  );
}

export default App;
