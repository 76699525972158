import React from 'react'
import {Button, Select, CircularProgress, FormControl, InputLabel, Grid, TextField} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import {Help} from '@material-ui/icons';
import MenuItem from '@material-ui/core/MenuItem';
import Pagination from '@material-ui/lab/Pagination';
import {v4 as uuidv4} from 'uuid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Event from './Event';

const circularstyle = { position: "absolute", top: "50%", left: "50%", margin: "0", marginRight: "-50%", transform: "translate(-50%, -50%)", textAlign: "center" };


function EventList(props) {
    const [data, setData] = React.useState(undefined);
    const [colors, setColors] = React.useState(undefined);
    const [search, setSearch] = React.useState("");
    const [currentPage, setCurrentPage] = React.useState(0); //Starts at 0
    const [perPage, setPerPage] = React.useState(25);
  
    const handleChangePage = (event, newPage) => {
      setCurrentPage(newPage - 1);
    };

 
  
    if (data == undefined) {
      let url;
      if (props.old) {
        url = global.localip + '/getAllOldData/';
      } else {
        url = global.localip + '/getAllData/';
      }
      fetch(url)
        .then(response => response.json())
        .then(result2 => {
          var results = result2;
          for (let i = 0; i < results.length; i++) {
            const event = results[i];
            let seats_booked = 0;
            for (let j = 0; j < event.bookingarray.length; j++) {
              const booking = event.bookingarray[j];
              seats_booked += booking.booking_seats;
            }
            event.seats_booked = seats_booked;
          }
  
          var filteredbyshow = [];
          for (let i = 0; i < results.length; i++) {
            if (results[i].event_status == 0 && props.show0) {
              filteredbyshow.push(results[i]);
            }
            if (results[i].event_status == 1 && props.show1) {
              filteredbyshow.push(results[i]);
            }
            if (results[i].event_status == 2 && props.show2) {
              filteredbyshow.push(results[i]);
            }
            if (results[i].event_status == 5 && props.show5) {
              filteredbyshow.push(results[i]);
            }
          }
  
          props.setAllEvents(filteredbyshow);
          setData(filteredbyshow);
        })
      return (<div style={circularstyle}>
        <CircularProgress size="100px"></CircularProgress>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        Lade Ereignisse...
      </div>);
    }
  
    if (colors == undefined) {
      let url = global.localip + '/getUserColors/';
      fetch(url)
        .then(response => response.json())
        .then(result2 => {
          setColors(result2);
        })
      return (<div style={circularstyle}>
        <CircularProgress size="100px"></CircularProgress>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        Lade Farben...
      </div>);
    }
  
    function EventIncludesText(str, obj) {
      let fieldstosearch = [];
      str = str.toLowerCase();
      fieldstosearch.push(obj.event_name);
      for (let i = 0; i < fieldstosearch.length; i++) {
        const element = fieldstosearch[i];
        var el = element.toLowerCase();
        if (el.includes(str)) {
          return true;
        }
      }
      return false;
    }
    function CleanBookingArray(str, bookingarray) {
      var newbookingarray = [];
      for (let i = 0; i < bookingarray.length; i++) {
        const booking = bookingarray[i];
        var fieldstosearch = [];
  
        fieldstosearch.push(booking.persondata[0].lastname);
        fieldstosearch.push(booking.persondata[0].firstname);
        fieldstosearch.push(booking.persondata[0].firstname + " " + booking.persondata[0].lastname);
        fieldstosearch.push(booking.persondata[0].lastname + " " + booking.persondata[0].firstname);
        fieldstosearch.push(booking.persondata[0].email);
        fieldstosearch.push(booking.answer);
  
        for (let i = 0; i < fieldstosearch.length; i++) {
          const element = fieldstosearch[i];
          var el = element.toLowerCase();
          if (el.includes(str)) {
            newbookingarray.push(booking);
            break;
          }
        }
      }
      return newbookingarray;
    }
  
  
    let filtered = [];
    for (let i = 0; i < data.length; i++) {
      const event = JSON.parse(JSON.stringify(data[i]));
      if (search == "") {
        filtered.push(event);
      } else {
        var cleanbookingarray = CleanBookingArray(search.toLowerCase(), event.bookingarray);
        var eventincludestext = EventIncludesText(search.toLowerCase(), event);
        if (eventincludestext || cleanbookingarray.length > 0) {
          if (cleanbookingarray.length == 0 && eventincludestext) {
            filtered.push(event);
          } else if (cleanbookingarray.length > 0) {
            event.bookingarray = cleanbookingarray;
            filtered.push(event);
          }
        }
      }
    }
  
    let filtered2 = [];
    for (let i = 0; i < perPage; i++) {
      if (filtered[perPage * currentPage + i] != undefined) {
        filtered2.push(filtered[perPage * currentPage + i]);
      }
    }
  
    let maxPages = parseInt((filtered.length - 1) / perPage) + 1;
  
    function startSearch() {
      let s = "";
      try {
        s = document.getElementById("searchInput").value;
      } catch (error) {
        console.log(error);
      }
      setCurrentPage(0);
      setSearch(s);
    }
  
    return (<div style={{ margin: "2%" }}>
      <h1>Schulungsteilnehmerübersicht</h1>
      <Grid container justify="flex-end">
        <Grid item xs={2}>
          <TextField
            id="searchInput"
            InputProps={{
              startAdornment: (
                <Tooltip title="Suche nach Events oder direkt nach Teilnehmern.">
                  <InputAdornment position="start">
                    <Help></Help>
                  </InputAdornment>
                </Tooltip>
              ),
            }} label={props.old ? "Archiv durchsuchen" : "Schulungen durchsuchen"} fullWidth
            onChange={(e) => {
              if (e.target.value == "") {
                setTimeout(() => {
                  startSearch();
                }, 1);
              }
            }}
            onKeyDown={(e) => {
              if (e.keyCode == 13) {
                startSearch();
              }
            }}>
          </TextField>
        </Grid>
        <Grid item xs={1}>
          <Button variant="outlined" type="submit" fullWidth variant="outlined" onClick={() => {
            startSearch();
          }}>Suchen</Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={1}>
          <FormControl fullWidth>
            <InputLabel>pro Seite</InputLabel>
            <Select
              value={perPage}
              onChange={(e) => {
                setCurrentPage(0);
                setPerPage(e.target.value);
              }}
              fullWidth
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={11}>
          <Pagination count={maxPages} page={currentPage + 1} onChange={handleChangePage}></Pagination>
        </Grid>
      </Grid>
      {filtered2.map(function (event, i) {
        return <Event allevents={props.allevents} rank={props.rank} colors={colors} event={event} key={uuidv4()}></Event>
      })}
    </div>)
  }
export default EventList
