import React from 'react'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Tooltip from '@material-ui/core/Tooltip';

function Status(props) {
    if (props.seats !== props.maxseats) {
      return (
        <div>
          <Tooltip title="Der Kurs ist noch nicht ausgebucht.">
            <FiberManualRecordIcon style={{ color: "red" }}></FiberManualRecordIcon>
          </Tooltip>
        </div>
      )
    } else {
      if (!props.allPaid) {
        return (
          <div>
            <Tooltip title="Der Kurs ist ausgebucht, es haben noch nicht alle bezahlt.">
              <FiberManualRecordIcon style={{ color: "orange" }}></FiberManualRecordIcon>
            </Tooltip>
          </div>
        )
      }
      return (
        <div>
          <Tooltip title="Der Kurs ist ausgebucht und alle haben bezahlt.">
            <FiberManualRecordIcon style={{ color: "green" }}></FiberManualRecordIcon>
          </Tooltip>
        </div>
      )
    }
  }

export default Status
