import React from 'react'
import MaterialTable from 'material-table';
import { Grid } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import Chart from "react-google-charts";

function StatisticsGraphics(props) {
    const data = props.data;
    const [checkedByPeople, setCheckedByPeople] = React.useState(false);
    const [linechartbyPeriod, setLinechartbyPeriod] = React.useState(false);
    const [linechartCheckedTypes, setLinechartCheckedTypes] = React.useState([]);

    const piechartdata = [];
    const linechartdata = [];


    function sortLineChart(a, b) {
        if(a[0] === "Jahr" || a[0] === "Monat"){
            return -1;
        }
        if(b[0] === "Jahr" || b[0] === "Monat"){
            return 1;
        }
        if (linechartbyPeriod) {
            let montha = parseInt(a[0].substring(5));
            let monthb = parseInt(b[0].substring(5));

            if (montha > monthb) {
                return 1;
            } else {
                return -1;
            }
        } else {
            if(a[0] > b[0]){
                return 1;
            } else {
                return -1;
            }
        }
    }

    function dateFormatLineChart(date) {
        if (linechartbyPeriod) {
            return "Monat " + (date.getMonth()+1);
        } else {
            return date.getFullYear() - 2000;
        }
    }

    function addLineChartData(type_name, date, value, tooltip) {
        if(linechartbyPeriod){
            if(!(date >= props.selectedDate1Start && date <= props.selectedDate1End)){
                return;
            } else {
                //type_name = "Zeitraum 1";
            }
        }

        //year = year - 2000;
        let found = false;
        let headerentrynumber;

        for (let i = 0; i < linechartdata[0].length; i++) {
            const headerentry = linechartdata[0][i];

            if (headerentry == type_name) {
                headerentrynumber = i;
            }
        }

        for (let j = 0; j < linechartdata.length; j++) {
            const entry = linechartdata[j];

            if (entry[0] == dateFormatLineChart(date)) {
                found = true;

                let oldvalue = entry[headerentrynumber];
                if (oldvalue == undefined) {
                    oldvalue = 0;
                }
                entry[headerentrynumber] = oldvalue + value;
                break;
            }
        }

        if (!found) {
            let testarr = [];
            testarr.push(dateFormatLineChart(date));
            for (let i = 1; i < linechartdata[0].length - 1; i++) {
                testarr.push(0);
            }
            testarr[headerentrynumber] = value;
            testarr.push(tooltip);
            linechartdata.push(testarr);
        }
    }

    function generatePieChartTooltip(name, value) {
        return checkedByPeople ? "<h5 style='font-size:14px; margin:5px;'>" + name + "<br> " + parseInt(value).toLocaleString("de") + "</h5>" : "<h5 style='font-size:14px; margin:5px;'>" + name + "<br> € " + parseInt(value).toLocaleString("de") + ",-</h5>";
    }

    function generateLineChartTooltip(name, year, value) {
        return "<h5 style='font-size:14px; margin:5px;'>" + name + "<br>Jahr: " + year + "<br>€ " + value + ",-</h5>";
    }

    if (Array.isArray(data)) {
        //Multiple
        if (data[0].eventsexist != undefined) {
            //Typ
            piechartdata.push(["Typ", "Wert", { role: "tooltip", type: "string", p: { html: true } }]);

            let linechartfirst = linechartbyPeriod ? ["Monat"] : ["Jahr"];
            for (let i = 0; i < data.length; i++) {
                const type = data[i];
                linechartfirst.push(type.type_name);
            }
            linechartfirst.push({ role: "tooltip", type: "string", p: { html: true } })
            linechartdata.push(linechartfirst);


            for (let i = 0; i < data.length; i++) {
                const type = data[i];
                let value = 0;

                for (let i = 0; i < type.eventarray.length; i++) {
                    const event = type.eventarray[i];
                    let price = 0;
                    let totalseatsbooked = event.statistics_totalseatsbooked;
                    try {
                        price = parseFloat(event.price);
                    } catch (error) { }

                    if (checkedByPeople) {
                        value = value + totalseatsbooked;
                        addLineChartData(type.type_name, event.event_start_date, totalseatsbooked, generateLineChartTooltip(type.type_name));
                    } else {
                        value = value + (totalseatsbooked * price);
                        addLineChartData(type.type_name, event.event_start_date, totalseatsbooked * price, generateLineChartTooltip(type.type_name));
                    }


                }

                let piechartentry = [type.type_name, value, generatePieChartTooltip(type.type_name, value)];
                piechartdata.push(piechartentry);
            }
        } else {
            //Events
            //console.log(data);
        }
    } else {
        //One
        if (data.eventsexist != undefined) {
            //Typ
        } else {
            //Event
        }
    }

    linechartdata.sort(sortLineChart)


    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {props.enableSwitch ? <>Euro
                        <Switch checked={checkedByPeople} onChange={() => {
                            setCheckedByPeople(!checkedByPeople)
                        }} name="Typ"></Switch>
                        Plätze gebucht</> : null}
                </Grid>
                <Grid item xs={12}>
                    {props.multipleEntrys ? null : (data.eventsexist ? <MaterialTable
                        icons={props.tableIcons}
                        data={data.eventarray}
                        title={"Alle Schulungen des ausgewählten Typs"}
                        columns={[
                            { title: 'Event Name', field: 'event_name' },
                            { title: 'Datum', field: 'event_start_date', type: "date", defaultSort: "desc" },
                            { title: 'Gebuchte Plätze', field: "statistics_totalseatsbooked", type: 'numeric' }
                        ]}
                    /> : null)}
                </Grid>
                {props.listTable ? null : <Grid item xs={6}>
                    <Chart
                        width={'100%'}
                        height={'300px'}
                        chartType="PieChart"
                        loader={<div>Loading Chart</div>}
                        data={piechartdata}
                        options={{
                            title: 'Kuchendiagramm ' + (checkedByPeople ? 'in gebuchten Plätzen' : "in Euro"),
                            tooltip: { isHtml: true, trigger: "visible" }
                        }}
                        rootProps={{ 'data-testid': '1' }}
                    />
                </Grid>}
                {props.listTable ? null : <Grid container item xs={6}>
                    <Grid item xs={8}>
                        <>Alles
                            <Switch checked={linechartbyPeriod} onChange={() => {
                                setLinechartbyPeriod(!linechartbyPeriod)
                            }} name="linechart"></Switch>
                            Eigener Zeitraum</>
                        <Chart
                            width={'100%'}
                            height={'300px'}
                            chartType="Line"
                            loader={<div>Loading Chart</div>}
                            data={linechartdata}

                            // chartPackages={['corechart', 'controls']}
                            // controls={[
                            //   {
                            //     controlType: 'DateRangeFilter',
                            //     options: {
                            //       filterColumnLabel: 'Jahr',
                            //       ui: { format: { pattern: 'yyyy' } },
                            //     },
                            //   },
                            // ]}
                            options={{
                                // legend: {
                                //     position: "none"
                                // },
                                chart: {
                                    title: 'Jahresdiagramm',
                                    subtitle: checkedByPeople ? 'in gebuchten Plätzen' : "in Euro",
                                },
                                tooltip: { isHtml: true, trigger: "visible" }
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>

                    </Grid>
                </Grid>}
            </Grid>
        </div>
    )
}

export default StatisticsGraphics
