import React from 'react';
import axios from 'axios';
import { Button, Grid, Paper, TextField } from '@material-ui/core';


function TemplateCopier(props) {
    let template = props.selectedTemplate;
    const [newName, setNewName] = React.useState(template.name + "_Kopie");

    function addTemplate() {
        let templatec = template;
        templatec.name = newName;

        axios.post(global.localip + '/addTemplate/', {
            template: templatec,
        }).then(function (result) {
            props.fetchTemplates(function () {
                props.setListSelect(result.data.insertId);
                props.setView("edit");
            });
        })
    }


    return (
        <div>
            <Grid container>
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                    <Paper style={{padding: "10px"}}>
                        <div >
                            <h3>Möchtest du das Template "{template.name}" kopieren?</h3>
                            <TextField value={newName} placeholder={"Neuer Name"} onChange={(e) => {setNewName(e.target.value)}}></TextField>
                            <Button variant="outlined" onClick={addTemplate}>Erstellen</Button>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={1}></Grid>
            </Grid>
        </div>
    )
}

export default TemplateCopier
