import React from 'react'
import { Grid, IconButton, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { Delete, FileCopy } from '@material-ui/icons';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Tooltip from '@material-ui/core/Tooltip';

function EventTypeListItem(props) {
    let type = props.type;

    return (<ListItem button>
        <ListItemText onClick={() => {
            props.setListSelect(type.id);
            props.setView("edit");
        }}>{type.type_name}</ListItemText>
        <ListItemSecondaryAction>
            <Tooltip title="Löschen">
                <IconButton onClick={() => {
                    props.setListSelect(type.id);
                    props.setView("delete");
                }}>
                    <Delete></Delete>
                </IconButton>
            </Tooltip>
        </ListItemSecondaryAction>
        {/*
            <Grid item xs={1}>
                <IconButton onClick={() => {
                    props.setListSelect(assignment.id);
                    props.setView("copy");
                }}>
                    <FileCopy></FileCopy>
                </IconButton> 
            </Grid>
                */}
    </ListItem>
    )
}

export default EventTypeListItem;