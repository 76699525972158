import React from 'react'
import { CircularProgress, Grid, Paper } from '@material-ui/core';
import AssignmentsList from './AssignmentsList';
import AssignmentCreator from './AssignmentCreator';
import AssignmentCopier from './AssignmentCopier';
import AssignmentEditor from './AssignmentEditor';
import AssignmentDeleter from './AssignmentDeleter';
import AssignmentWelcome from './AssignmentWelcome';
import KeyWordList from '../TemplateManager/KeyWordList';

function AssignmentsManager(props) {
    let templates = props.templates;
    const [view, setView] = React.useState("start");
    const [assignments, setAssignments] = React.useState(undefined);
    const [listselect, setListSelect] = React.useState(0);
    const [events, setEvents] = React.useState(undefined);

    function loadEvents() {
        let url = global.localip + '/getAllData';
        fetch(url)
            .then(response => response.json())
            .then(result => {
                setEvents(result);
            })
    }

    if(events == undefined){
        loadEvents();
        return <CircularProgress></CircularProgress>
    }

    if (assignments === undefined) {
        fetchAssignments();
        return <CircularProgress></CircularProgress>
    }

    function fetchAssignments(callback) {
        let url = global.localip + '/getAssignments';
        fetch(url)
            .then(response => response.json())
            .then(result => {
                setAssignments(result);
                if (callback !== undefined) {
                    callback();
                }
            })
    }

    function getSelectedAssignment() {
        for (let i = 0; i < assignments.length; i++) {
            const assignment = assignments[i];
            if (assignment.id === listselect) {
                return assignment;
            }
        }
        return undefined;
    }

    let selectedAssignment = getSelectedAssignment();

    function viewSwitcher() {
        switch (view) {
            case "create":
                return <AssignmentCreator fetchAssignments={fetchAssignments} setView={setView} setListSelect={setListSelect}></AssignmentCreator>
            case "copy":
                return <AssignmentCopier selectedAssignment={selectedAssignment} fetchAssignments={fetchAssignments} setView={setView} setListSelect={setListSelect} key={listselect}></AssignmentCopier>
            case "edit":
                return <AssignmentEditor events={events} templates={templates} selectedAssignment={selectedAssignment} fetchAssignments={fetchAssignments} key={listselect}></AssignmentEditor>
            case "delete":
                return <AssignmentDeleter selectedAssignment={selectedAssignment} fetchAssignments={fetchAssignments} setView={setView} key={listselect}></AssignmentDeleter>
            case "start":
                return <AssignmentWelcome></AssignmentWelcome>

            default:
                return null;
        }
    }

    return (<Grid container>
        <Grid item xs={2}>
            <h3>Zuweisungen</h3>
            <Paper>
                <AssignmentsList assignments={assignments} setListSelect={setListSelect} setView={setView}></AssignmentsList>
            </Paper>
        </Grid>
        <Grid item xs={8}>
            {viewSwitcher()}
        </Grid>
        <Grid item xs={2}>
            <h3>Übersicht über Schlüsselwörter</h3>
            <Paper>
                <KeyWordList></KeyWordList>
            </Paper>
        </Grid>
    </Grid>
    )
}

export default AssignmentsManager
