import React from 'react'
import {CircularProgress} from '@material-ui/core';
import Event from './Event';

const circularstyle = { position: "absolute", top: "50%", left: "50%", margin: "0", marginRight: "-50%", transform: "translate(-50%, -50%)", textAlign: "center" };

function EventFake100000(props) {
    const [fetched, setFetched] = React.useState(false);
    const [event, setEvent] = React.useState(undefined);
  
    if (!fetched) {
      let url = global.localip + '/getEvents/';
      fetch(url)
        .then(response => response.json())
        .then(result => {
          let url = global.localip + '/getFakeData/';
          fetch(url)
            .then(response => response.json())
            .then(result2 => {
              props.setAllEvents(result);
              for (let i = 0; i < result2.length; i++) {
                const event = result2[i];
                let seats_booked = 0;
                for (let j = 0; j < event.bookingarray.length; j++) {
                  const booking = event.bookingarray[j];
                  seats_booked += booking.booking_seats;
                }
                event.seats_booked = seats_booked;
              }
  
              result2[0].event_id = 100000;
              result2[0].event_name = "Warteliste";
              result2[0].event_seats = 100;
              result2[0].event_start_date = "2020-01-01T22:00:00.000Z"
              result2[0].event_end_date = "2020-01-01T22:00:00.000Z"
              result2[0].event_start_time = "09:00:00"
              result2[0].event_end_time = "09:00:00"
              result2[0].price = "0"
              result2[0].currency = "EUR"
  
              setEvent(result2[0]);
              setFetched(true);
            })
        })
      return <div style={circularstyle}>
        <CircularProgress size="100px"></CircularProgress>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      Lade Warteliste...
    </div>;
    }
    if (event === undefined) {
      return null;
    }
  
    return <Event allevents={props.allevents} rank={props.rank} event={event}></Event>
  }

export default EventFake100000
