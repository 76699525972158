import { Button, Checkbox, CircularProgress, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, Paper } from '@material-ui/core';
import React from 'react'


const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    paper: {
        width: 300,
        height: 230,
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function AssignmentTransferList(props) {
    const classes = useStyles();
    const assignment = props.assignment;

    function setSelected(select) {
        let assignmentc = JSON.parse(JSON.stringify(assignment));
        assignmentc.assigned_to = JSON.stringify(select);
        props.setAssignment(assignmentc);
    }

    const selected = JSON.parse(assignment.assigned_to);

    const [checked, setChecked] = React.useState([]);
    const [available, setAvailable] = React.useState(undefined);
    const [events, setEvents] = React.useState(undefined);


    if(available === undefined){
        let url = global.localip + '/getEvents';
        fetch(url)
            .then(response => response.json())
            .then(result => {
                setEvents(result);
                let avarr = [];
                for (let i = 0; i < result.length; i++) {
                    const element = result[i];
                    if(!selected.includes(element.event_id)){
                        avarr.push(element.event_id);
                    }
                }

                setAvailable(avarr);
            })

        return <CircularProgress></CircularProgress>
    } else if(events === undefined){
        return <CircularProgress></CircularProgress>
    } else {
        //Remove Selected Deleted
        let selectedc = JSON.parse(JSON.stringify(selected));
        for (let i = 0; i < selectedc.length; i++) {
            if(getEventByEventId(selectedc[i]) === undefined){
                selectedc.splice(i, 1);
                i--;
            }
        }
        if(selected.length != selectedc.length){
            setSelected(selectedc);
            return <CircularProgress></CircularProgress>
        }
    }

    function getEventByEventId(event_id) {
        for (let i = 0; i < events.length; i++) {
            const element = events[i];
            if(element.event_id === event_id){
                return element;
            }
        }
        return undefined;
    }


    const leftChecked = intersection(checked, available);
    const rightChecked = intersection(checked, selected);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setSelected(selected.concat(available));
        setAvailable([]);
    };

    const handleCheckedRight = () => {
        setSelected(selected.concat(leftChecked));
        setAvailable(not(available, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setAvailable(available.concat(rightChecked));
        setSelected(not(selected, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setAvailable(available.concat(selected));
        setSelected([]);
    }

    const customList = (items) => (
        <Paper className={classes.paper}>
            <List dense component="div" role="list" title="test">
                {items.map((element) => {
                    let event = getEventByEventId(element);
                    let id = element;
                    const labelId = `transfer-list-item-${id}-label`;
                    return (
                        <ListItem key={id} role="listitem" button onClick={handleToggle(id)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(id) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={event.event_name} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );

    return (
        <Grid container spacing={2} justify="flex-start" alignItems="center" className={classes.root}>
            <Grid item>{customList(available)}</Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleAllRight}
                        disabled={available.length === 0}
                        aria-label="move all right"
                    >
                        ≫
            </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected right"
                    >
                        &gt;
            </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                    >
                        &lt;
            </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleAllLeft}
                        disabled={selected.length === 0}
                        aria-label="move all left"
                    >
                        ≪
            </Button>
                </Grid>
            </Grid>
            <Grid item>{customList(selected)}</Grid>
        </Grid>
    );
}

export default AssignmentTransferList
