import React from 'react'
import {Grid, IconButton, List, ListSubheader} from '@material-ui/core';
import {Add} from '@material-ui/icons';
import AssignmentListItem from './AssignmentListItem';

function AssignmentsList(props) {
    return (
        <List
            subheader={<ListSubheader>
                <Grid container justify="flex-end">
                    <IconButton edge="end" onClick={() => {
                            props.setView("create");
                        }}>
                        <Add></Add>
                    </IconButton>
                </Grid>
            </ListSubheader>}>
            {props.assignments.map(function (assignment, i) {
                return <AssignmentListItem assignment={assignment} key={i} setListSelect={props.setListSelect} setView={props.setView}></AssignmentListItem>
            })}
        </List>
    )
}

export default AssignmentsList;
