import React from 'react'

function TemplateWelcome() {
    return (
        <div>
            Willkommensnachricht / Erklärung folgt...
        </div>
    )
}

export default TemplateWelcome;
