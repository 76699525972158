import React from 'react'
import { List, Button, Grid, TextField } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import UserListItem from './UserListItem';

function ManageUsers(props) {
  let rank = props.rank;
  const [users, setUsers] = React.useState([]);
  const [automatedLogout, setAutomatedLogout] = React.useState(undefined);
  const [open, setOpen] = React.useState(false);

  if (rank != 2) {
    return (
      <div>

      </div>
    )
  } else {
    if (users.length === 0) {
      let url = global.localip + '/allUsers';
      fetch(url)
        .then(response => response.json())
        .then(result => {
          setUsers(result);
        })
    }

    function handleOpen(opened) {
      if (opened) {
        fetchUsers();
      }
      setOpen(opened);
    }

    function fetchUsers() {
      let url = global.localip + '/allUsers';
      fetch(url)
        .then(response => response.json())
        .then(result => {
          setUsers([]);
          setUsers(result);
        })
    }

    if (automatedLogout === undefined) {
      let url = global.localip + '/getAutomatedLogout';
      fetch(url)
        .then(response => response.json())
        .then(result => {
          setAutomatedLogout(result[0].automated_logout);
        })
      return null;
    }

    function getLogoutInFormat() {
      var num = automatedLogout;
      var hours = (num / 60);
      var rhours = ("0" + Math.floor(hours)).slice(-2);
      var minutes = (hours - rhours) * 60;
      var rminutes = ("0" + Math.round(minutes)).slice(-2);
      return rhours + ":" + rminutes;
    }

    return (
      <div style={{ overflow: "hidden", margin: "5px" }}>
        <Button variant="contained" color="secondary" onClick={() => {
          handleOpen(true);
          let url = global.localip + '/allUsers';
          fetch(url)
            .then(response => response.json())
            .then(result => {
              setUsers(result);
            })
        }}>
          Verwaltung
        </Button>
        <Dialog onClose={() => {
          handleOpen(false);
        }} open={open} fullWidth>
          <DialogTitle id="simple-dialog-title">Verwaltung</DialogTitle>
          <h4 style={{ margin: "20px" }}>Hier kannst du Nutzer bearbeiten.</h4>
          <List>
            {users.map(function (user, i) {
              return <UserListItem user={user} setOpen={handleOpen} fetchUsers={fetchUsers} key={i}></UserListItem>
            })}
          </List>
          <Grid container style={{ margin: "20px" }} spacing={1}>
            <Grid item xs={12}>
              <h4>Hier kannst du neue Nutzer anlegen.</h4>
            </Grid>
            <Grid item xs={6}>
              <TextField id="new_name" label="Vorname"></TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField id="new_lastname" label="Nachname"></TextField>
            </Grid>
            <Grid item xs={10}>
              <TextField fullWidth id="new_mail" label="E-Mail" type="email"></TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField id="new_pw1" label="Passwort" type="password"></TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField id="new_pw2" label="Passwort wiederholen" type="password"></TextField>
            </Grid>
            <br></br>
            <Grid item xs={12}>
              <Button variant="contained" onClick={() => {
                var name = document.getElementById("new_name").value;
                var lastname = document.getElementById("new_lastname").value;
                var mail = document.getElementById("new_mail").value;
                var pw1 = document.getElementById("new_pw1").value;
                var pw2 = document.getElementById("new_pw2").value;
                if (pw1 === pw2) {
                  let url = global.localip + '/newUser/' + mail + "/" + name + "/" + lastname + "/" + pw1;
                  fetch(url)
                    .then(response => response.json())
                    .then(result => {
                      let url = global.localip + '/allUsers';
                      fetch(url)
                        .then(response => response.json())
                        .then(result => {
                          document.getElementById("new_name").value = "";
                          document.getElementById("new_lastname").value = "";
                          document.getElementById("new_mail").value = "";
                          document.getElementById("new_pw1").value = "";
                          document.getElementById("new_pw2").value = "";
                          setUsers(result);
                        })
                    })
                } else {
                  alert("Die Passwörter müssen übereinstimmen");
                }
              }}>Nutzer hinzufügen</Button>
            </Grid>
          </Grid>
          <h4 style={{ margin: "20px" }}>Allgemeine Einstellungen</h4>
          <Grid container style={{ margin: "20px" }}>
            <Grid item xs={4}>
              Automatisches Ausloggen nach:&nbsp;&nbsp;&nbsp;
            </Grid>
            <Grid item xs={7}>
              <TextField
                fullWidth
                id="time"
                label="Logout nach"
                type="time"
                value={getLogoutInFormat()}
                onChange={(e) => {
                  let valparts = e.target.value.split(":");
                  let hours = parseInt(valparts[0]);
                  let minutes = parseInt(valparts[1]);
                  let totalminutes = hours * 60 + minutes;
                  if (totalminutes <= 0) {
                    totalminutes = 1;
                  }
                  setAutomatedLogout(totalminutes);


                  let url = global.localip + '/setAutomatedLogout/' + totalminutes;
                  fetch(url)
                    .then(response => response.json())
                    .then(result => { })
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />
            </Grid>
          </Grid>
        </Dialog>
      </div>
    )
  }
}

export default ManageUsers
