import React from 'react'
import { ListItem, ListItemText, ListItemSecondaryAction, Button, Select, Grid, TextField, Checkbox, List, DialogActions } from '@material-ui/core';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import PersonIcon from '@material-ui/icons/Person';
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import { PhotoshopPicker } from 'react-color';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';


function UserListItem(props) {
  var user = props.user;
  const [value, setValue] = React.useState(user.rank);
  const [color, setColor] = React.useState("#" + user.color);
  const [editUser, setEditUser] = React.useState(false);

  const [permitted_views, setPermittedViews] = React.useState(JSON.parse(user.permitted_views));

  const DialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" onClick={onClose} style={{ position: "absolute", top: "10px", right: "10px" }}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };

  function changePermittedView(viewid) {
    //console.log(booking);
    let permitted_viewsc = [...permitted_views];
    if (permitted_views.includes(viewid)) {
      const index = permitted_viewsc.indexOf(viewid);
      if (index > -1) {
        permitted_viewsc.splice(index, 1);
      }
    } else {
      permitted_viewsc.push(viewid);
    }
    setPermittedViews(permitted_viewsc);

    axios.post(global.localip + '/changePermittedViews/' + user.id, {
      views: JSON.stringify(permitted_viewsc),
    })
      .then((response) => {
        //console.log(response);
      }, (error) => {
        console.log(error);
      });
  }


  function changeViewsbyRank(rank) {
    let permitted_viewstemp = [];
    if (rank == 0) {
      permitted_viewstemp = [0, 1, 2, 3];
    } else if (rank == 1) {
      permitted_viewstemp = [0, 1, 2, 3];
    } else if (rank == 2) {
      permitted_viewstemp = [0, 1, 2, 3, 4, 5, 6, 7];
    } else if (rank == 3) {
      permitted_viewstemp = [3];
    }

    setPermittedViews(permitted_viewstemp);

    axios.post(global.localip + '/changePermittedViews/' + user.id, {
      views: JSON.stringify(permitted_viewstemp),
    })
      .then((response) => {
        //console.log(response);
      }, (error) => {
        console.log(error);
      });
  }

  function EditUser() {
    return (
      <div style={{ margin: "0px 20px 10px 20px" }}>
        <Grid container spacing={1}>
          <Grid container item xs={12}>
            <Grid item xs={8}>
              <h4>Freigeschaltete Ansichten</h4>
            </Grid>
            <Grid item xs={4}>
              <br></br>
              <Select
                value={value}
                onChange={(e) => {
                  let url = global.localip + '/setUserRank/' + user.id + "/" + e.target.value;
                  fetch(url)
                    .then(response => response.json())
                    .then(result => {
                      setValue(e.target.value);
                      changeViewsbyRank(e.target.value);
                    })
                }}
              >
                <MenuItem value={0}>Betrachter</MenuItem>
                <MenuItem value={1}>Bearbeiter</MenuItem>
                <MenuItem value={2}>Admin</MenuItem>
                <MenuItem value={3}>Kalender</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={4}>
              Schulungen:
              <Checkbox
                checked={permitted_views.includes(0)}
                onChange={() => { changePermittedView(0) }}
              />
            </Grid>
            <Grid item xs={4}>
              Schulungsarchiv:
              <Checkbox
                checked={permitted_views.includes(1)}
                onChange={() => { changePermittedView(1) }}
              />
            </Grid>
            <Grid item xs={4}>
              Warteliste:
              <Checkbox
                checked={permitted_views.includes(2)}
                onChange={() => { changePermittedView(2) }}
              />
            </Grid>
            <Grid item xs={12}>
              Kalender:
              <Checkbox
                checked={permitted_views.includes(3)}
                onChange={() => { changePermittedView(3) }}
              />
            </Grid>
            <Grid item xs={4}>
              Vorlagen:
              <Checkbox
                checked={permitted_views.includes(4)}
                onChange={() => { changePermittedView(4) }}
              />
            </Grid>
            <Grid item xs={4}>
              Kurstypen:
              <Checkbox
                checked={permitted_views.includes(5)}
                onChange={() => { changePermittedView(5) }}
              />
            </Grid>
            <Grid item xs={4}>
            </Grid>
            <Grid item xs={4}>
              Statistik:
              <Checkbox
                checked={permitted_views.includes(6)}
                onChange={() => { changePermittedView(6) }}
              />
            </Grid>
            <Grid item xs={4}>
              Nutzerverwaltung:
              <Checkbox
                checked={permitted_views.includes(7)}
                onChange={() => { changePermittedView(7) }}
              />
            </Grid>
            <Grid item xs={4}>
              Einstellungen:
              <Checkbox
                checked={true}
                disabled
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <h4>Farbeinstellungen</h4>
            <PhotoshopPicker
              color={color}
              onChangeComplete={(color) => {
                setColor(color.hex);
              }}
              onAccept={() => {
                let hex = color.replace("#", "");
                let url = global.localip + '/setUserColor/' + user.id + "/" + hex;
                fetch(url)
                  .then(response => response.json())
                  .then(result => {
                    setEditUser(false);
                  })
              }}
              onCancel={() => {
                setColor("#" + user.color);
                setEditUser(false);
              }}>
            </PhotoshopPicker>
          </Grid>
          <Grid item xs={11}>
            <Button fullWidth variant="outlined" onClick={() => {
              let url = global.localip + '/setUserColor/' + user.id + "/null";
              fetch(url)
                .then(response => response.json())
                .then(result => {
                  setColor("#");
                })
            }}>
              Farbe entfernen
            </Button>
          </Grid>
          <Grid item xs={12}>
            <h4>Passwort ändern</h4>
          </Grid>
          <Grid item xs={4}>
            <TextField label="Passwort ändern" id="changedpw1"></TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField label="Passwort wiederholen" id="changedpw2"></TextField>
          </Grid>
          <Grid item xs={4}>
            <Button variant="contained" onClick={() => {
              if (document.getElementById("changedpw1").value === document.getElementById("changedpw2").value) {
                let url = global.localip + '/changePassword/' + user.id + "/" + document.getElementById("changedpw1").value;
                fetch(url)
                  .then(response => response.json())
                  .then(result => {
                    props.setOpen(false);
                  })
              } else {
                alert("Die eingegebenen Passwörter stimmen nicht überein.")
              }
            }}>Passwort ändern</Button>
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth color="secondary" variant="contained" onClick={() => {
              let url = global.localip + '/deleteUser/' + user.id;
              fetch(url)
                .then(response => response.json())
                .then(result => {
                  props.setOpen(false);
                })
            }}>Nutzer löschen</Button>
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth variant="contained" onClick={() => {
              props.fetchUsers();
              setEditUser(false);
            }}>Bearbeiten abschließen</Button>
          </Grid>
        </Grid>
      </div>
    )
  }
  return (
    <ListItem button key={user} style={{ backgroundColor: color }}>
      <Dialog onClose={() => {
        setEditUser(false);
      }} open={editUser} fullWidth>
        <DialogTitle onClose={() => setEditUser(false)}>Du bearbeitest gerade: {user.surname + " " + user.name}</DialogTitle>
        <DialogActions>
          <Button></Button>
        </DialogActions>
        <EditUser></EditUser>
      </Dialog>
      <ListItemAvatar onClick={() => {
        setEditUser(true);
      }}>
        <Avatar>
          <PersonIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={user.surname + " " + user.name} onClick={() => {
        setEditUser(true);
      }} />
      <ListItemSecondaryAction>
        <Select
          value={value}
          onChange={(e) => {
            let url = global.localip + '/setUserRank/' + user.id + "/" + e.target.value;
            fetch(url)
              .then(response => response.json())
              .then(result => {
                setValue(e.target.value);
                changeViewsbyRank(e.target.value);
              })
          }}
        >
          <MenuItem value={0}>Betrachter</MenuItem>
          <MenuItem value={1}>Bearbeiter</MenuItem>
          <MenuItem value={2}>Admin</MenuItem>
          <MenuItem value={3}>Kalender</MenuItem>
        </Select>
      </ListItemSecondaryAction>
    </ListItem>
  )
}
export default UserListItem
