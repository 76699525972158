import React from 'react'

function OtherEvents(props) {
    const [data, setData] = React.useState(undefined);
  
    if (data === undefined) {
      let url = global.localip + '/getAllEventsbyPeopleId/' + props.person[0].person_id;
      fetch(url)
        .then(response => response.json())
        .then(result2 => {
          console.log(result2);
          setData(result2);
        })
      return "Lade Events...";
    }
    
    return (
      <div style={{ margin: "10px" }}>
        {data.map((event) => {
          if(event != undefined){
            return <p>{event.event_name}</p>
          }
        })}
      </div>
    )
  }

export default OtherEvents
