import React from 'react'
import {Grid, IconButton, List, ListSubheader} from '@material-ui/core';
import {Add} from '@material-ui/icons';
import EventTypeListItem from './EventTypeListItem';

function EventTypeList(props) {
    return (
        <List
            subheader={<ListSubheader>
                <Grid container justify="flex-end">
                    <IconButton edge="end" onClick={() => {
                            props.setView("create");
                        }}>
                        <Add></Add>
                    </IconButton>
                </Grid>
            </ListSubheader>}>
            {props.types.map(function (type, i) {
                return <EventTypeListItem type={type} key={i} setListSelect={props.setListSelect} setView={props.setView}></EventTypeListItem>
            })}
        </List>
    )
}

export default EventTypeList;
