import React from 'react'
import { Grid, IconButton, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { Delete, FileCopy } from '@material-ui/icons';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Tooltip from '@material-ui/core/Tooltip';

function AssignmentListItem(props) {
    let assignment = props.assignment;

    return (<ListItem button>
        <ListItemAvatar>
            {assignment.assigned_to === "none" || assignment.template_type == 0 || assignment.template_id == 0 ?
                <Tooltip title="Zuweisung inaktiv">
                    <FiberManualRecordIcon style={{ color: "red" }}></FiberManualRecordIcon>
                </Tooltip> :
                <Tooltip title="Zuweisung aktiv">
                    <FiberManualRecordIcon style={{ color: "green" }}></FiberManualRecordIcon>
                </Tooltip>}
        </ListItemAvatar>
        <ListItemText onClick={() => {
            props.setListSelect(assignment.id);
            props.setView("edit");
        }}>{assignment.name}</ListItemText>
        <ListItemSecondaryAction>
            <Tooltip title="Löschen">
                <IconButton onClick={() => {
                    props.setListSelect(assignment.id);
                    props.setView("delete");
                }}>
                    <Delete></Delete>
                </IconButton>
            </Tooltip>
        </ListItemSecondaryAction>
        {/*
            <Grid item xs={1}>
                <IconButton onClick={() => {
                    props.setListSelect(assignment.id);
                    props.setView("copy");
                }}>
                    <FileCopy></FileCopy>
                </IconButton> 
            </Grid>
                */}
    </ListItem>
    )
}

export default AssignmentListItem
