import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

let timeouttemp;


function LogOutButton(props) {
    const [automatedLogout, setAutomatedLogout] = React.useState(undefined);
    const [currentS, setCurrentS] = React.useState(0);

    React.useEffect(() => {
        document.addEventListener("keydown", resetS);
        document.addEventListener("mousedown", resetS);
    }, []);

    function resetS() {
        setCurrentS(0);
    }

    function increaseS() {
        setCurrentS(currentS + 1);
    }

    // React.useEffect(() => {
    clearTimeout(timeouttemp);
    timeouttemp = setTimeout(() => {
        increaseS();
    }, 1000);
    //})

    let secondsLogOut = automatedLogout * 60 - currentS;

    function logOut() {
        localStorage.removeItem('userid');
        localStorage.removeItem('rank');
        localStorage.removeItem('loginstamp');
        window.location.reload();
    }

    function secondstoHHMMSS(totalSeconds) {
        var sec_num = totalSeconds;
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);

        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        seconds = parseInt(seconds);
        if (seconds < 10) { seconds = "0" + seconds; }
        return hours + ':' + minutes + ':' + seconds;
    }

    if (automatedLogout === undefined) {
        let url = global.localip + '/getAutomatedLogout';
        fetch(url)
            .then(response => response.json())
            .then(result => {
                setAutomatedLogout(result[0].automated_logout);
            })

        return null;
    }

    if (secondsLogOut === 0) {
        logOut();
    }

    return (<>
        <div style={{ position: "absolute", right: props.right, top: props.top }}>
            {secondstoHHMMSS(secondsLogOut)}
            <IconButton edge="end" onClick={logOut}><ExitToAppIcon fontSize="large"></ExitToAppIcon></IconButton>
        </div>
    </>);
}



export default LogOutButton