import React from 'react'
import axios from 'axios';
import {Button, Grid, Paper, TextField} from '@material-ui/core';

function AssignmentCopier(props) {
    let assignment = props.selectedAssignment;
    const [newName, setNewName] = React.useState(assignment.name + "_Kopie");

    function addAssignment() {
        let assignmentc = assignment;
        assignmentc.name = newName;

        axios.post(global.localip + '/addAssignment/', {
            assignment: assignmentc,
        }).then(function (result) {
            props.fetchAssignments(function () {
                props.setListSelect(result.data.insertId);
                props.setView("edit");
            });
        })
    }


    return (
        <div>
            <Grid container>
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                    <Paper style={{padding: "10px"}}>
                        <div >
                            <h3>Möchtest du die Zuweisung "{assignment.name}" kopieren?</h3>
                            <TextField value={newName} placeholder={"Neuer Name"} onChange={(e) => {setNewName(e.target.value)}}></TextField>
                            <Button variant="outlined" onClick={addAssignment}>Erstellen</Button>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={1}></Grid>
            </Grid>
        </div>
    )
}

export default AssignmentCopier
