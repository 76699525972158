import React from 'react'
import axios from 'axios';
import { Button, Grid, Paper } from '@material-ui/core';

function TemplateDeleter(props) {
    let template = props.selectedTemplate;

    function deleteTemplate() {
        axios.post(global.localip + '/deleteTemplate/', {
            template: template,
        }).then(function (result) {
            console.log(result);
            if(result.data.error !== undefined){
                alert("Leider ist diese Vorlage in Benutzung. Wenn du diese Vorlage dennoch entfernen möchtest gehe bitte sicher, dass keine Zuweisung die Vorlage nutzt.")
            } else {
                props.fetchTemplates();
                props.setView("start");
            }
        })
    }


    return (
        <div>
            <Grid container>
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                    <Paper style={{ padding: "10px" }}>
                        <div >
                            <h3>Willst du dieses Template wirklich entfernen?</h3>
                            <h4>Name: {template.name}</h4>
                            <Button variant="outlined" onClick={deleteTemplate}>Entfernen</Button>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={1}></Grid>
            </Grid>
        </div>
    )
}

export default TemplateDeleter
