import React from 'react'
import { Chip, List, ListItem, ListItemText, Checkbox, Button, Grid, TextField } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import axios from 'axios';
import MoveListItem from './MoveListItem';
import OtherEvents from './OtherEvents';

function CustomListItem(props) {
  var booking = props.booking;
  let rank = props.rank;
  let allevents = props.allevents;
  const [persondata, setPersonData] = React.useState(undefined);
  const [open, setOpen] = React.useState(false);
  const [checked, setChecked] = React.useState(Boolean(booking.booking_paid));
  const [checked2, setChecked2] = React.useState(false);
  const [checked3, setChecked3] = React.useState(false);
  const [checked4, setChecked4] = React.useState(false);
  const [checked5, setChecked5] = React.useState(false);
  const [checked6, setChecked6] = React.useState(false);
  const [openComment, setOpenComment] = React.useState(false);
  const [openOtherEvents, setOpenOtherEvents] = React.useState(false);
  const [openQueue, setOpenQueue] = React.useState(false);
  const [comment, setComment] = React.useState(booking.booking_comment);

  if (persondata == undefined) {
    setPersonData(booking.persondata);
    setChecked(Boolean(booking.booking_paid));
    if (booking.bookingdetails.length > 0) {
      if (booking.bookingdetails[0].scriptsent == 1) {
        setChecked2(true);
      }
      if (booking.bookingdetails[0].testsent == 1) {
        setChecked3(true);
      }
      if (booking.bookingdetails[0].testmade == 1) {
        setChecked4(true);
      }
      if (booking.bookingdetails[0].folderreceived == 1) {
        setChecked5(true);
      }
      if (booking.bookingdetails[0].requirement == 1) {
        setChecked6(true);
      }


      if (booking.bookingdetails[0].scriptsent == 1) {
        if (booking.bookingdetails[0].testsent == 1) {
          if (booking.bookingdetails[0].testmade == 1) {
            if (booking.bookingdetails[0].folderreceived == 1) {
              props.increaseCheckedAmount()
            }
          }
        }
      }
    }
    return <>Loading</>;
  }

  const handleChange = (event) => {
    if (rank == 0) {
      alert("Du besitzt nicht die erforderlichen Rechte um zu Bearbeiten.")
      return;
    }

    let checking = event.target.checked;
    //console.log(booking);
    var num = 0;
    if (event.target.checked == true) {
      num = 1;
    }
    let url = global.localip + '/setBookingPaid/' + booking.booking_id + "/" + num;
    fetch(url)
      .then(response => response.json())
      .then(result2 => {
        setChecked(checking);
        if(props.changeBookingDetailsCalendarCallBack != undefined){
          props.changeBookingDetailsCalendarCallBack(booking.booking_id, "booking_paid", num);
        }
      })
  };
  const handleChangeScript = (event) => {
    if (rank == 0) {
      alert("Du besitzt nicht die erforderlichen Rechte um zu Bearbeiten.")
      return;
    }

    let checking = event.target.checked;
    //console.log(booking);
    var num = 0;
    if (event.target.checked == true) {
      num = 1;
    }
    let url = global.localip + '/checkBookingScript/' + booking.booking_id + "/" + num;
    fetch(url)
      .then(response => response.json())
      .then(result => {
        //console.log(result);
        setChecked2(checking);
        props.changeBookingDetailsCalendarCallBack(booking.booking_id, "scriptsent", num);
      })
  };
  const handleChangeTestLink = (event) => {
    if (rank == 0) {
      alert("Du besitzt nicht die erforderlichen Rechte um zu Bearbeiten.")
      return;
    }

    let checking = event.target.checked;
    //console.log(booking);
    var num = 0;
    if (event.target.checked == true) {
      num = 1;
    }
    let url = global.localip + '/checkBookingTest/' + booking.booking_id + "/" + num;
    fetch(url)
      .then(response => response.json())
      .then(result => {
        //console.log(result);
        setChecked3(checking);
        props.changeBookingDetailsCalendarCallBack(booking.booking_id, "testsent", num);
      })
  };
  const handleChangeTestmade = (event) => {
    if (rank == 0) {
      alert("Du besitzt nicht die erforderlichen Rechte um zu Bearbeiten.")
      return;
    }

    let checking = event.target.checked;
    //console.log(booking);
    var num = 0;
    if (event.target.checked == true) {
      num = 1;
    }
    let url = global.localip + '/checkBookingTestDone/' + booking.booking_id + "/" + num;
    fetch(url)
      .then(response => response.json())
      .then(result => {
        //console.log(result);
        setChecked4(checking);
        props.changeBookingDetailsCalendarCallBack(booking.booking_id, "testmade", num);
      })
  };
  const handleChangeFolderReceived = (event) => {
    if (rank == 0) {
      alert("Du besitzt nicht die erforderlichen Rechte um zu Bearbeiten.")
      return;
    }

    let checking = event.target.checked;
    //console.log(booking);
    var num = 0;
    if (event.target.checked == true) {
      num = 1;
    }
    let url = global.localip + '/checkBookingFolderReceived/' + booking.booking_id + "/" + num;
    fetch(url)
      .then(response => response.json())
      .then(result => {
        //console.log(result);
        setChecked5(checking);
        props.changeBookingDetailsCalendarCallBack(booking.booking_id, "folderreceived", num);
      })
  };
  const handleChangeRequirement = (event) => {
    if (rank == 0) {
      alert("Du besitzt nicht die erforderlichen Rechte um zu Bearbeiten.")
      return;
    }

    let checking = event.target.checked;
    //console.log(booking);
    var num = 0;
    if (event.target.checked == true) {
      num = 1;
    }
    let url = global.localip + '/checkBookingRequirement/' + booking.booking_id + "/" + num;
    fetch(url)
      .then(response => response.json())
      .then(result => {
        //console.log(result);
        setChecked6(checking);
        props.changeBookingDetailsCalendarCallBack(booking.booking_id, "requirement", num);
      })
  };


  function handleListItemClick(eventid, reason) {
    let url = global.localip + '/changeEventID/' + booking.booking_id + "/" + eventid + "/" + reason;
    setOpen(false);
    setOpenQueue(false);
    fetch(url)
      .then(response => response.json())
      .then(result => {
        window.location.reload();
      })
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseComment = () => {
    setOpenComment(false);
    axios.post(global.localip + '/updateComment/' + booking.booking_id, {
      comment: comment,
    })
      .then((response) => {
        //console.log(response);
      }, (error) => {
        console.log(error);
      });
  };
  const handleCloseOtherEvents = () => {
    setOpenOtherEvents(false);
  };

  var platz = "";
  if (booking.booking_seats == 1) {
    platz = " 1 Platz";
  } else {
    platz = booking.booking_seats + " Plätze";
  }

  return (
    <ListItem key={booking}>
      <Grid container justify="flex-start">
        <Grid container spacing={3} item xs={3}>
          <Grid item xs={10}>
            <Grid style={{ marginBottom: "5px" }} container justify="space-between">
              <Grid item xs={12}>
                <Chip label={"Am " + new Date(booking.creation_date).toLocaleDateString("de-DE", { year: "numeric", month: "numeric", day: "numeric" }) + " gebucht: " + platz}></Chip>
              </Grid>
            </Grid>
            <table style={{ border: "2px solid black", borderCollapse: "collapse", width: "100%" }}>
              <tbody>
                <tr>
                  <td style={{ backgroundColor: "#95d5b2", border: "1px solid black", borderCollapse: "collapse", padding: "3px 7px" }}>
                    <p style={{ margin: "0", padding: "0" }}>{persondata[0].firstname + " " + persondata[0].lastname}</p>
                    <small style={{ margin: "0", padding: "0" }}>{"Email: " + persondata[0].email}</small><br></br>
                    <small style={{ margin: "0", padding: "0" }}>Büro: {booking.answer}</small>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: "3px 7px" }}>
                    <span style={{ whiteSpace: "pre-line", wordBreak: "break-all" }}>Kommentar: {comment}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
        </Grid>
        <Grid container justify="space-evenly" spacing={1} item xs={9}>
          <Grid item xs={12}></Grid>
          <Grid container justify="space-evenly" item xs={12}>
            <Grid item xs={"auto"}>
              Bezahlt:
              <Checkbox
                checked={checked}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={"auto"}>
              Voraussetzungen:
              <Checkbox
                checked={checked6}
                onChange={handleChangeRequirement}
              />
            </Grid>
            <Grid item xs={"auto"}>
              Skript gesendet:
              <Checkbox
                checked={checked2}
                onChange={handleChangeScript}
              />
            </Grid>
            <Grid item xs={"auto"}>
              Testlink gesendet:
              <Checkbox
                checked={checked3}
                onChange={handleChangeTestLink}
              />
            </Grid>
            <Grid item xs={"auto"}>
              Test gemacht:
              <Checkbox
                checked={checked4}
                onChange={handleChangeTestmade}
              />
            </Grid>
            <Grid item xs={"auto"}>
              Punkte AK:
              <Checkbox
                checked={booking.answer_ak !== undefined && !booking.answer_ak.includes("keine")}
                disabled
              />
            </Grid>
            <Grid item xs={"auto"}>
              Ordner erhalten:
              <Checkbox
                checked={checked5}
                onChange={handleChangeFolderReceived}
              />
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Button fullWidth style={{ backgroundColor: "#8E9BB4", color: "white" }} variant="contained" onClick={() => {
              setOpenComment(true);
            }}>Kommentar bearbeiten</Button>
          </Grid>
          <Grid item xs={4}>
            <Button fullWidth style={{ backgroundColor: "#8E9BB4", color: "white" }} variant="contained" onClick={() => {
              setOpen(true);
            }}>Buchung verschieben</Button>
          </Grid>
          <Grid item xs={4}>
            <Button fullWidth style={{ backgroundColor: "#e9896a", color: "white" }} variant="contained" onClick={() => {
              setOpenOtherEvents(true);
            }}>Besuchte Kurse anzeigen</Button>
          </Grid>
        </Grid>
      </Grid>
      {open ?
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
          <DialogTitle id="simple-dialog-title">Wähle eine Schulung, in die die Buchung verschoben werden soll.</DialogTitle>
          <List>
            <ListItem button onClick={() => {
              setOpen(false);
              setOpenQueue(true);
            }}
              key={100000}>
              <ListItemText primary="Auf Unbekannt verschieben" />
            </ListItem>
            {allevents.map((event) => (
              <MoveListItem event={event} booking={booking} setOpen={setOpen}></MoveListItem>
            ))}
          </List>
        </Dialog>
        : null}
      {openQueue ?
        <Dialog onClose={() => { setOpenQueue(false) }} aria-labelledby="simple-dialog-title" open={openQueue}>
          <DialogTitle id="simple-dialog-title">Wähle den Grund, warum die Schulung nun in der Warteliste ist.</DialogTitle>
          <List>
            <ListItem button onClick={() => handleListItemClick(100000, 0)}>
              <ListItemText primary="Zu geringe Teilnehmerzahl" />
            </ListItem>
            <ListItem button onClick={() => handleListItemClick(100000, 1)}>
              <ListItemText primary="Schulungsteilnehmer hat abgesagt" />
            </ListItem>
          </List>
        </Dialog>
        : null}
      {openComment ?
        <Dialog onClose={handleCloseComment} aria-labelledby="simple-dialog-title" open={openComment}>
          <DialogTitle id="simple-dialog-title">Kommentar bearbeiten / setzen</DialogTitle>
          <TextField helperText={"Noch " + (10000 - comment.length) + " Zeichen"} multiline placeholder="Kommentar" variant="filled" value={comment} onChange={(e) => {
            if (comment.length < 10000 || e.target.value.length < comment.length) {
              //console.log(e.target.value);
              setComment(e.target.value);
            } else {

            }
          }}></TextField>
        </Dialog>
        : null}
      {openOtherEvents ?
        <>
          <Dialog onClose={handleCloseOtherEvents} aria-labelledby="simple-dialog-title" open={openOtherEvents}>
            <DialogTitle id="simple-dialog-title">Bereits besuchte Schulungen</DialogTitle>
            <OtherEvents person={persondata}></OtherEvents>
            {/*<TextField helperText={"Noch " + (10000 - comment.length) + " Zeichen"} multiline placeholder="Kommentar" variant="filled" value={comment} onChange={(e) => {
                if (comment.length < 10000 || e.target.value.length < comment.length) {
                  console.log(e.target.value);
                  setComment(e.target.value);
                } else {
  
                }
              }}></TextField>*/}
          </Dialog>
        </>
        : null}
    </ListItem>
  )
}

export default CustomListItem
