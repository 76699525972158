import React from 'react'
import axios from 'axios';
import {Button, Grid, Paper} from '@material-ui/core';

function AssignmentDeleter(props) {
    let assignment = props.selectedAssignment;

    function deleteAssignment() {
        axios.post(global.localip + '/deleteAssignment/', {
            assignment: assignment,
        }).then(function () {
            props.fetchAssignments();
            props.setView("start");
        })
    }


    return (
        <div>
            <Grid container>
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                    <Paper style={{ padding: "10px" }}>
                        <div >
                            <h3>Willst du diese Zuweisung wirklich entfernen?</h3>
                            <h4>Name: {assignment.name}</h4>
                            <Button variant="outlined" onClick={deleteAssignment}>Entfernen</Button>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={1}></Grid>
            </Grid>
        </div>
    )
}

export default AssignmentDeleter
