import React from 'react'
import {Button, CircularProgress, Grid, MenuItem, Paper, Select, TextField, Dialog} from '@material-ui/core';
import axios from 'axios';
import AssignmentTransferList from './AssignmentTransferList';

function AssignmentEditor(props) {
    let templates = props.templates;
    const [assignment, setAssignment] = React.useState(props.selectedAssignment);

    const [testEvent, setTestEvent] = React.useState(0);
    const [testBooking, setTestBooking] = React.useState(0);
    const [testMailOpen, setTestMailOpen] = React.useState(false);
    const [testmail, setTestMail] = React.useState("");

    function sendTestMail() {
        if (testmail !== "" && testBooking !== 0 && testEvent !== 0) {
            let url = global.localip + '/sendTestMail/' + assignment.id + "/" + testmail + "/" + testEvent + "/" + testBooking;
            fetch(url)
                .then(response => response.json())
                .then(result => {
                    setTestMail("");
                    setTestMailOpen(false);
                    setTestBooking(0);
                    setTestEvent(0);
                    //console.log(result);
                })
        }
    }


    let eventObjSelected;
    for (let i = 0; i < props.events.length; i++) {
        const event = props.events[i];
        if (event.event_id == testEvent) {
            eventObjSelected = event;
        }
    }

    if (assignment === undefined) {
        return <CircularProgress></CircularProgress>
    }


    function saveAssignment() {
        axios.post(global.localip + '/updateAssignment/', {
            assignment: assignment,
        }).then(function (result) {
            props.fetchAssignments(function () {
            });
        })
    }

    function changeSelectedTemplateID(e) {
        let assignmentc = JSON.parse(JSON.stringify(assignment));
        assignmentc.template_id = e.target.value;
        setAssignment(assignmentc);
    }

    function changeSelectedTemplateType(e) {
        let assignmentc = JSON.parse(JSON.stringify(assignment));
        assignmentc.template_type = e.target.value;
        setAssignment(assignmentc);
    }

    function changeSelectedAssignedTo(e) {
        let assignmentc = JSON.parse(JSON.stringify(assignment));
        assignmentc.assigned_to = e.target.value;
        setAssignment(assignmentc);
    }

    function specialItemValue() {
        if (assignment.assigned_to !== "none" && assignment.assigned_to !== "all") {
            return assignment.assigned_to;
        } else {
            return "[]";
        }
    }
    function specialItemRender() {
        if (assignment.assigned_to !== "none" && assignment.assigned_to !== "all") {
            return true;
        } else {
            return false;
        }
    }


    return <div style={{ margin: "20px" }}>
        <Grid container spacing={3}>
            <Grid item xs={4}>
                <TextField value={assignment.name} onChange={(e) => {
                    let assignmentc = JSON.parse(JSON.stringify(assignment));
                    assignmentc.name = e.target.value;
                    setAssignment(assignmentc);
                }}></TextField>
            </Grid>
            <Grid item xs={3}></Grid>            
            <Grid item xs={2}><Button fullWidth variant="outlined" color="primary" onClick={() => setTestMailOpen(true)}>Testmail</Button></Grid>
            <Grid item xs={3}>
                <Button fullWidth variant="outlined" color="primary" onClick={saveAssignment}>Zuweisung speichern</Button>
            </Grid>
        </Grid>
        <Paper style={{ padding: "10px" }}>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    1. Vorlage wählen:<br></br>
                    <Select
                        value={assignment.template_id}
                        onChange={changeSelectedTemplateID}
                    >
                        <MenuItem value={0}>Bitte wählen</MenuItem>
                        {templates.map((template, i) => (
                            <MenuItem value={template.id} key={i}>{template.name}</MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={12}>
                    2. Vorlagenart setzen:<br></br>
                    <Select
                        value={assignment.template_type}
                        onChange={changeSelectedTemplateType}
                    >
                        <MenuItem value={0}>Bitte wählen</MenuItem>
                        <MenuItem value={1}>Buchungsbestätigung</MenuItem>
                        <MenuItem value={2}>Warteliste - Teilnehmer abgesagt</MenuItem>
                        <MenuItem value={3}>Warteliste - Teilnehmeranzahl nicht ausreichend</MenuItem>
                        <MenuItem value={4} disabled>Zertifikate</MenuItem>
                    </Select>
                </Grid>
                <Grid container item xs={12}>
                    <Grid item xs={12}>
                        3. Mailbetreff setzen:<br></br>
                        <TextField value={assignment.subject} onChange={(e) => {
                            let assignmentc = JSON.parse(JSON.stringify(assignment));
                            assignmentc.subject = e.target.value;
                            setAssignment(assignmentc);
                        }}></TextField>
                    </Grid>
                    {assignment.template_type === 1 ? <>
                        <Grid item xs={12}>
                            <br></br>
                            3.2 Links bei Bedarf setzen
                        </Grid>
                        <Grid item xs={4}>
                            Link zum Meeting:
                            <TextField value={assignment.linkmeet} onChange={(e) => {
                                let assignmentc = JSON.parse(JSON.stringify(assignment));
                                assignmentc.linkmeet = e.target.value;
                                setAssignment(assignmentc);
                            }}></TextField>
                        </Grid>
                        <Grid item xs={4}>
                            Link zu Dateien:
                            <TextField value={assignment.linkfiles} onChange={(e) => {
                                let assignmentc = JSON.parse(JSON.stringify(assignment));
                                assignmentc.linkfiles = e.target.value;
                                setAssignment(assignmentc);
                            }}></TextField>
                        </Grid>
                    </> : null}

                </Grid>
                {assignment.template_type !== 0 &&
                    <Grid item xs={12}>
                        4. Zuweisung:<br></br>
                        <Select
                            value={assignment.assigned_to}
                            onChange={changeSelectedAssignedTo}
                        >
                            <MenuItem value={"none"}>Bitte wählen</MenuItem>
                            <MenuItem value={"all"}>Für Alle anwenden</MenuItem>
                            <MenuItem value={specialItemValue()} disabled>Für bestimmte Schulungen anwenden</MenuItem>
                        </Select>
                    </Grid>}
                {specialItemRender() &&
                    <Grid item xs={12}>
                        5. Auswahl:<br></br>
                        <AssignmentTransferList assignment={assignment} setAssignment={setAssignment}></AssignmentTransferList>
                    </Grid>}
            </Grid>
        <Dialog
            open={testMailOpen}
            onClose={() => setTestMailOpen(false)}
        >
            <div style={{ margin: "20px" }}>
                <Grid container spacing={3} style={{ padding: "5px" }}>
                    <Grid item xs={12}>
                        <TextField label="Mail-Adresse" fullWidth value={testmail} onChange={(e) => {
                            setTestMail(e.target.value);
                        }}></TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <Select
                            value={testEvent}
                            onChange={(e) => setTestEvent(e.target.value)}
                        >
                            <MenuItem value={0}>Schulung wählen</MenuItem>
                            {props.events.map(function (event) {
                                return <MenuItem value={event.event_id}>{event.event_name}</MenuItem>;
                            })}
                        </Select>
                    </Grid>
                    <Grid item xs={12}>
                        {testEvent !== 0 && eventObjSelected !== undefined ?
                            <Select
                                value={testBooking}
                                onChange={(e) => setTestBooking(e.target.value)}
                            >
                                <MenuItem value={0}>Buchung wählen</MenuItem>

                                {eventObjSelected.bookingarray.map(function (booking) {
                                return <MenuItem value={booking.booking_id}>{booking.persondata[0].lastname}, {booking.persondata[0].firstname}</MenuItem>;
                            })}
                            </Select> : null}
                    </Grid>
                    <Grid item xs={4}>
                        <Button fullWidth variant="outlined" color="primary" onClick={sendTestMail}>Senden</Button>
                    </Grid>
                </Grid>
            </div>
        </Dialog>
        </Paper>
    </div>;
}

export default AssignmentEditor
