import React from 'react'
import { Checkbox, Button, Select, Card, CardContent, CardActions, Grid } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Tab from '@material-ui/core/Tab';


function Settings(props) {
  function updateSettingstoDB() {
    let url = global.localip + '/setUserSettings/' + props.userid + "/" + props.defaultview + "/" + (props.show0 ? 1 : 0) + "/" + (props.show1 ? 1 : 0) + "/" + (props.show2 ? 1 : 0) + "/" + (props.show5 ? 1 : 0);
    fetch(url)
      .then(response => response.json())
      .then(result => { })
  }

  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            Standartansicht:
            <Select
              value={props.defaultview}
              onChange={(e) => {
                props.setDefaultView(e.target.value);
              }}
            >
              {props.permitted_views.map(function (view) {
                switch (view) {
                  case 0:
                    return <MenuItem key={view} value={view}>Schulungen</MenuItem>;
                  case 1:
                    return <MenuItem key={view} value={view}>Archiv</MenuItem>;
                  case 2:
                    return <MenuItem key={view} value={view}>Warteliste</MenuItem>;
                  case 3:
                    return <MenuItem key={view} value={view}>Kalender</MenuItem>;
                  case 4:
                    return <MenuItem key={view} value={view}>Vorlagen</MenuItem>;
                  case 5:
                    return <MenuItem key={view} value={view}>Statistik</MenuItem>;
                  case 6:
                    return <MenuItem key={view} value={view}>Kurstypen</MenuItem>;

                  default:
                    break;
                }
              })}
            </Select>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              Gelöschte Schulungen
              <Checkbox
                checked={props.show0}
                onChange={() => {
                  props.setShow0(!props.show0);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              Öffentliche Schulungen
              <Checkbox
                checked={props.show1}
                onChange={() => {
                  props.setShow1(!props.show1);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              Private Schulungen
              <Checkbox
                checked={props.show2}
                onChange={() => {
                  props.setShow2(!props.show2);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              Entwurfs-Schulungen
              <Checkbox
                checked={props.show5}
                onChange={() => {
                  props.setShow5(!props.show5);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button variant="outlined" color="primary" onClick={updateSettingstoDB}>Speichern</Button>
      </CardActions>
    </Card>
  )
}

export default Settings
