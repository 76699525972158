import { Grid, IconButton, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { Delete, FileCopy } from '@material-ui/icons';

import React from 'react'
import Tooltip from '@material-ui/core/Tooltip';

function TemplateListItem(props) {
    let template = props.template;

    return <ListItem button>
        <ListItemText onClick={() => {
            props.setListSelect(template.id);
            props.setView("edit");
        }}>{template.name}</ListItemText>
        <ListItemSecondaryAction>
            <Tooltip title="Löschen">
                <IconButton onClick={() => {
                    props.setListSelect(template.id);
                    props.setView("delete");
                }}>
                    <Delete></Delete>
                </IconButton>
            </Tooltip>
            <Tooltip title="Kopieren">
                <IconButton onClick={() => {
                    props.setListSelect(template.id);
                    props.setView("copy");
                }}>
                    <FileCopy></FileCopy>
                </IconButton>
            </Tooltip>
        </ListItemSecondaryAction>
    </ListItem>
}

export default TemplateListItem;
