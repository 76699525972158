import React from 'react'
import { Button, CircularProgress, Grid, MenuItem, Paper, Select, TextField } from '@material-ui/core';
import axios from 'axios';


function EventTypeEditor(props) {
    let types = props.types;
    const [type, setType] = React.useState(props.selectedType);

    if (type === undefined) {
        return <CircularProgress></CircularProgress>
    }

    function saveType() {
        axios.post(global.localip + '/updateType/', {
            type: type,
        }).then(function (result) {
            props.fetchTypes(function () {
            });
        })
    }



    return <div style={{ margin: "20px" }}>
        <Grid container spacing={3}>
            <Grid item xs={4}>
                <TextField value={type.type_name} onChange={(e) => {
                    let typec = JSON.parse(JSON.stringify(type));
                    typec.type_name = e.target.value;
                    setType(typec);
                }}></TextField>
            </Grid>
            <Grid item xs={5}></Grid>
            <Grid item xs={3}>
                <Button fullWidth variant="outlined" color="primary" onClick={saveType}>Typ speichern</Button>
            </Grid>
        </Grid>
        <Paper style={{ padding: "10px" }}>
            <Grid container spacing={2}>
                <Grid container item xs={12}>
                    <Grid item xs={2}>
                        Event Name beinhaltet:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField value={type.contains} onChange={(e) => {
                            let typec = JSON.parse(JSON.stringify(type));
                            typec.contains = e.target.value;
                            setType(typec);
                        }}></TextField>
                    </Grid>
                    <Grid item xs={1}>
                        und
                    </Grid>
                    <Grid item xs={2}>
                        <TextField value={type.contains2} onChange={(e) => {
                            let typec = JSON.parse(JSON.stringify(type));
                            typec.contains2 = e.target.value;
                            setType(typec);
                        }}></TextField>
                    </Grid>
                </Grid>
                <Grid container item xs={12}>
                    <Grid item xs={2}>
                        Event Name fängt an mit:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField value={type.starts_with} onChange={(e) => {
                            let typec = JSON.parse(JSON.stringify(type));
                            typec.starts_with = e.target.value;
                            setType(typec);
                        }}></TextField>
                    </Grid>
                    <Grid item xs={1}>
                        oder
                    </Grid>
                    <Grid item xs={2}>
                        <TextField value={type.starts_with2} onChange={(e) => {
                            let typec = JSON.parse(JSON.stringify(type));
                            typec.starts_with2 = e.target.value;
                            setType(typec);
                        }}></TextField>
                    </Grid>
                </Grid>
                <Grid container item xs={12}>
                    <Grid item xs={2}>
                        Event Name endet auf:
                    </Grid>
                    <Grid item xs={2}>
                        <TextField value={type.ends_with} onChange={(e) => {
                            let typec = JSON.parse(JSON.stringify(type));
                            typec.ends_with = e.target.value;
                            setType(typec);
                        }}></TextField>
                    </Grid>
                    <Grid item xs={1}>
                        oder
                    </Grid>
                    <Grid item xs={2}>
                        <TextField value={type.ends_with2} onChange={(e) => {
                            let typec = JSON.parse(JSON.stringify(type));
                            typec.ends_with2 = e.target.value;
                            setType(typec);
                        }}></TextField>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    </div>;
}

export default EventTypeEditor;
