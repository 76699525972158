import React from 'react'
import {CircularProgress} from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import Event from '../Events/Event';
import LogOutButton from '../General/LogOutButton';

const circularstyle = { position: "absolute", top: "50%", left: "50%", margin: "0", marginRight: "-50%", transform: "translate(-50%, -50%)", textAlign: "center" };

function Calendar(props) {
    const [data, setData] = React.useState(undefined);
    const [colors, setColors] = React.useState(undefined);
    const [open, setOpen] = React.useState(false);
    const [modalEvent, setModalEvent] = React.useState(undefined);
    const [eventswithpeople, setPeople] = React.useState(undefined);
    const [eventstate, setEventstate] = React.useState(undefined);
    const [peopleevents, setPeopleEvents] = React.useState(undefined);
    var events = [];

    function changeBookingDetails(booking_id, field, value) {
      if(data != undefined){
        let datacopy = [...data];
        for (let i = 0; i < datacopy.length; i++) {
          const event = datacopy[i];
          for (let j = 0; j < event.bookingarray.length; j++) {
            const booking = event.bookingarray[j];
            if(booking.booking_id == booking_id){
              if(field == "booking_paid"){
                booking[field] = value;
              } else {
                if(booking.bookingdetails[0] != undefined){
                  booking.bookingdetails[0][field] = value;
                }
              }
            }
          }
        }
        setData(datacopy);
      }
    
    }
  
    function changeLecturer(lecturer, eventid) {
      let datac = [...data];
      for (let i = 0; i < datac.length; i++) {
        const event = datac[i];
        if (event.event_id == eventid) {
          event.event_lecturer = lecturer;
        }
      }
      setData(datac);
      setEventstate(undefined);
      let eventswithpeoplec = [...eventswithpeople];
      for (let i = 0; i < eventswithpeoplec.length; i++) {
        const element = eventswithpeoplec[i];
        for (let j = 0; j < element.length; j++) {
          const element2 = element[j];
          if (element2 != undefined) {
            if (element2.event.event_id == eventid) {
              element2.event.event_lecturer = lecturer;
            }
          }
        }
      }
      setPeople(eventswithpeoplec);
      let peopleeventsc = [...peopleevents];
      for (let i = 0; i < peopleeventsc.length; i++) {
        const element = peopleeventsc[i];
        if (element.id == eventid) {
          for (let i = 0; i < colors.length; i++) {
            const color = colors[i];
            if (color.id == lecturer) {
              element.color = shadeColor("#" + color.color, 15);
              element.defaultcolor = shadeColor("#" + color.color, 15);
              break;
            }
          }
        }
      }
  
      setPeopleEvents(peopleeventsc);
    }
  
  
    if (colors == undefined) {
      let url = global.localip + '/getUserColors/';
      fetch(url)
        .then(response => response.json())
        .then(result2 => {
          setColors(result2);
        })
      return (<div style={circularstyle}>
        <CircularProgress size="100px"></CircularProgress>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        Lade Farben...
      </div>);
    }
  
    if (data == undefined) {
      let url = global.localip + '/getFullData/';
      fetch(url)
        .then(response => response.json())
        .then(result => {
          var filtered = [];
          for (let i = 0; i < result.length; i++) {
            if (result[i].event_status == 0 && props.show0) {
              filtered.push(result[i]);
            }
            if (result[i].event_status == 1 && props.show1) {
              filtered.push(result[i]);
            }
            if (result[i].event_status == 2 && props.show2) {
              filtered.push(result[i]);
            }
            if (result[i].event_status == 5 && props.show5) {
              filtered.push(result[i]);
            }
          }
          result = filtered;
  
          for (let i = 0; i < result.length; i++) {
            const event = result[i];
            let seats_booked = 0;
            for (let j = 0; j < event.bookingarray.length; j++) {
              const booking = event.bookingarray[j];
              seats_booked += booking.booking_seats;
            }
            event.seats_booked = seats_booked;
          }
  
          props.setAllEvents(result);
  
          var eventswithpeoples = [];
          for (let i = 0; i < result.length; i++) {
            const element = result[i];
            //console.log(element);
            let result2 = element.bookingarray;
            //console.log(result2);
            //console.log(result2);
            var peoplearray = [];
            for (let j = 0; j < result2.length; j++) {
              const booking = result2[j];
              let result3 = booking.persondata;
              if (result3 != undefined && result3.length > 0) {
                peoplearray.push({ name: result3[0].firstname + " " + result3[0].lastname + " | " + result2[j].answer, event: element });
              }
            }
            eventswithpeoples.push(peoplearray);
          }
          setData(result);
          setPeople(eventswithpeoples);
        })
    } else {
      //setTimeout(() => {
      if (eventstate == undefined) {
        //Day Events
  
        let url = 'https://feiertage-api.de/api/?jahr=' + new Date().getFullYear() + '&nur_land=HH';
        fetch(url)
          .then(response => response.json())
          .then(result => {
            for (const key in result) {
              if (Object.hasOwnProperty.call(result, key)) {
                const element = result[key];
                var dateparts = element.datum.split("-");
                var e = {};
                var day = new Date(parseInt(dateparts[0]), parseInt(dateparts[1]) - 1, parseInt(dateparts[2]));
  
                e.title = key;
                //e.disabled = true;
                e.start = day;
                e.end = day;
                e.allDay = true;
                e.color = "#FC5751";
                e.defaultcolor = "#FC5751";
                e.disabled = true;
                events.push(e);
              }
            }
  
            for (let i = 0; i < data.length; i++) {
              const element = data[i];
              var e = {};
              e.title = element.event_name;
              e.id = element.event_id;
              e.start = element.event_start_date;
              e.isEvent = true;
              var end = new Date(element.event_end_date);
              end.setDate(end.getDate() + 1);
              e.end = end;
              e.allDay = true;
              if (element.event_lecturer == 0) {
                e.color = '#779ECB';
                e.defaultcolor = '#779ECB';
              } else {
                for (let i = 0; i < colors.length; i++) {
                  const color = colors[i];
                  if (color.id == element.event_lecturer) {
                    e.color = "#" + color.color;
                    e.defaultcolor = "#" + color.color;
                    break;
                  }
                }
              }
              events.push(e);
            }
            setEventstate(events);
          })
      } else if (peopleevents == undefined) {
        if (eventswithpeople != undefined) {
          for (let i = 0; i < eventswithpeople.length; i++) {
            const element = eventswithpeople[i];
  
            for (let i = 0; i < element.length; i++) {
              const personandevent = element[i];
  
              var e = {};
              e.title = personandevent.name;
              e.id = personandevent.event.event_id;
              e.start = personandevent.event.event_start_date;
              var end = new Date(personandevent.event.event_end_date);
              end.setDate(end.getDate() + 1);
              e.end = end;
              e.allDay = true;
  
              if (personandevent.event.event_lecturer == 0) {
                e.color = '#77DD77';
                e.defaultcolor = '#77DD77';
              } else {
                for (let i = 0; i < colors.length; i++) {
                  const color = colors[i];
                  if (color.id == personandevent.event.event_lecturer) {
                    e.color = shadeColor("#" + color.color, 15);
                    e.defaultcolor = shadeColor("#" + color.color, 15);
                    break;
                  }
                }
              }
  
              events.push(e);
            }
          }
          setPeopleEvents(events)
        }
      }
    }
  
    function handleEventClick(params) {
      //console.log(params);
      var eid = params.event._def.publicId;
  
      for (let i = 0; i < data.length; i++) {
        const event = data[i];
        if (event.event_id == eid) {
          setModalEvent(event);
          setOpen(true);
          break;
        }
      }
    }
  
    //if (eventstate != undefined) {
    //console.log(eventstate);
    if (peopleevents == undefined) {
      return (<div style={circularstyle}>
        <CircularProgress size="100px"></CircularProgress>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
          Lade Personendaten...
      </div>)
    } else {
      var earr = eventstate;
      if (earr == undefined) {
        earr = [];
      }
  
      var parr = peopleevents;
  
      var all = earr.concat(parr);
  
  
      return (
        <div
          onScroll={(e) => {
            /*console.log(e);
            console.log(e.target);*/
          }}>
          <LogOut></LogOut>
          <FullCalendar
            tooltip
            eventOrder={(first, second) => {
              if (first.isEvent && second.isEvent) {
                if (first.event_id >= second.event_id) {
                  return -1;
                } else if (second.event_id >= first.event_id) {
                  return 1;
                }
              } else if (first.isEvent) {
                return -1;
              } else if (second.isEvent) {
                return 1;
              } else {
                if (first.event_id >= second.event_id) {
                  return -1;
                } else if (second.event_id >= first.event_id) {
                  return 1;
                }
              }
  
              return 1;
            }}
            plugins={[dayGridPlugin]}
            eventClick={handleEventClick}
            events={all}
            firstDay={1}
            weekNumbers={true}
            initialDate={new Date()}
            eventTextColor="black"
          ></FullCalendar>
          <Dialog key={modalEvent != undefined ? modalEvent.event_id : Math.random()} onClose={() => {
            setOpen(false);
          }} open={open} maxWidth={"xl"}>
            <DialogTitle id="simple-dialog-title">Kursübersicht</DialogTitle>
            <Event calendar allevents={props.allevents} rank={props.rank} event={modalEvent} colors={colors} changeBookingDetailsCalendarCallBack={changeBookingDetails} setCalendarDialog={setOpen}  changeLecturerCallBack={changeLecturer} closeModal={() => {
              setOpen(false);
            }}></Event>
          </Dialog>
        </div>
      )
    }
    //}
  
    function LogOut() {
      if (props.logOut) {
        return (
          <LogOutButton right="180px" top="-5px"></LogOutButton>
        )
      } else {
        return (<></>)
      }
    }
  
  }

export default Calendar





// Version 4.0
function shadeColor(color, percent) {
    var R = parseInt(color.substring(1, 3), 16);
    var G = parseInt(color.substring(3, 5), 16);
    var B = parseInt(color.substring(5, 7), 16);
  
    R = parseInt(R * (100 + percent) / 100);
    G = parseInt(G * (100 + percent) / 100);
    B = parseInt(B * (100 + percent) / 100);
  
    R = (R < 255) ? R : 255;
    G = (G < 255) ? G : 255;
    B = (B < 255) ? B : 255;
  
    var RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
    var GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
    var BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));
  
    return "#" + RR + GG + BB;
  }
  