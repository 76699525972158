import MaterialTable, { MTableHeader } from "material-table";
import React from "react";
import { forwardRef } from "react";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { CircularProgress, Grid } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import { Equalizer } from "@material-ui/icons";
import StatisticsGraphics from "./StatisticsGraphics";
import "date-fns";

import { CustomtableHeader } from "./CustomtableHeader";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function getDatesBetween(startDate, stopDate) {
  var dateArray = new Array();
  var currentDate = startDate;
  while (currentDate <= stopDate) {
    dateArray.push(new Date(currentDate));
    currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
  }
  return dateArray;
}

function getFirstDateOfCurrentYear() {
  let date = new Date(0);
  date.setFullYear(new Date().getFullYear());
  return date;
}
function getFirstDateOfLastYear() {
  let date = new Date(0);
  date.setFullYear(new Date().getFullYear() - 1);
  return date;
}
function getTodayOfLastYear() {
  let date = new Date();
  date.setFullYear(date.getFullYear() - 1);
  return date;
}

function StatisticsManager(props) {
  const [data, setData] = React.useState(undefined);
  const [types, setTypes] = React.useState(undefined);
  const [checkedByType, setCheckedByType] = React.useState(true);
  const [selectedStatistics, setSelectedStatistics] = React.useState(undefined);
  const [selectedDate1Start, setSelectedDate1Start] = React.useState(
    getFirstDateOfCurrentYear()
  );
  const [selectedDate1End, setSelectedDate1End] = React.useState(new Date());
  const [selectedDate2Start, setSelectedDate2Start] = React.useState(
    getFirstDateOfLastYear()
  );
  const [selectedDate2End, setSelectedDate2End] = React.useState(
    getTodayOfLastYear()
  );

  const [availableQuickDatesYears, setAvailableQuickDatesYears] =
    React.useState([]);
  const [availableQuickDatesMonth, setAvailableQuickDatesMonths] =
    React.useState([]);
  const [availableQuickDatesQuartal, setAvailableQuickDatesQuartal] =
    React.useState([]);

  if (types === undefined) {
    fetchTypes();
    return <CircularProgress></CircularProgress>;
  }

  function fetchTypes(callback) {
    let url = global.localip + "/getTypes";
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        setTypes(result);
        if (callback !== undefined) {
          callback();
        }
      });
  }

  if (data === undefined) {
    let url = global.localip + "/getFullData";
    fetch(url)
      .then((response) => response.json())
      .then((results) => {
        let firstdate = new Date();
        let lastdate = new Date();
        for (let i = 0; i < results.length; i++) {
          const event = results[i];
          event.event_start_date = new Date(event.event_start_date);

          if (event.event_start_date < firstdate) {
            firstdate = event.event_start_date;
          }
          if (new Date(event.event_end_date) > lastdate) {
            lastdate = new Date(event.event_end_date);
          }

          let statistics_totalseatsbooked = 0;
          for (let j = 0; j < event.bookingarray.length; j++) {
            const booking = event.bookingarray[j];
            statistics_totalseatsbooked += booking.booking_seats;
          }
          event.statistics_totalseatsbooked = statistics_totalseatsbooked;
        }
        setData(results);

        /*setSelectedDate1Start(firstdate);
                setSelectedDate2Start(firstdate);
                setSelectedDate1End(lastdate);
                setSelectedDate2End(lastdate);*/

        let datearr = getDatesBetween(firstdate, lastdate);
        let availableQuickDatesYearsc = [...availableQuickDatesYears];
        let availableQuickDatesQuartalc = [...availableQuickDatesQuartal];
        for (let i = 0; i < datearr.length; i++) {
          const date = datearr[i];
          let year = date.getFullYear();
          let quartal;
          let month;
          switch (date.getMonth()) {
            case 0:
              quartal = 1;
              month = "Januar";
              break;
            case 1:
              quartal = 1;
              month = "Februar";
              break;
            case 2:
              quartal = 1;
              month = "März";
              break;
            case 3:
              quartal = 2;
              month = "April";
              break;
            case 4:
              quartal = 2;
              month = "Mai";
              break;
            case 5:
              quartal = 2;
              month = "Juni";
              break;
            case 6:
              quartal = 3;
              month = "Juli";
              break;
            case 7:
              quartal = 3;
              month = "August";
              break;
            case 8:
              quartal = 3;
              month = "Septempber";
              break;
            case 9:
              quartal = 4;
              month = "Oktober";
              break;
            case 10:
              quartal = 4;
              month = "November";
              break;
            case 11:
              quartal = 4;
              month = "Dezember";
              break;

            default:
              break;
          }

          if (!availableQuickDatesYearsc.includes(year)) {
            availableQuickDatesYearsc.push(year);
          }
          let quartalstr = "Q" + quartal + " " + year;
          if (!availableQuickDatesQuartalc.includes(quartalstr)) {
            availableQuickDatesQuartalc.push(quartalstr);
          }
        }
        setAvailableQuickDatesQuartal(availableQuickDatesQuartalc);
        setAvailableQuickDatesYears(availableQuickDatesYearsc);
      });
    return <CircularProgress></CircularProgress>;
  }

  //console.log(data);
  let typedata = [];

  for (let i = 0; i < types.length; i++) {
    const type = types[i];
    for (let j = 0; j < data.length; j++) {
      const event = data[j];

      if (
        type.contains != "" &&
        event.event_name.toLowerCase().includes(type.contains.toLowerCase())
      ) {
        if (
          type.contains2 == "" ||
          event.event_name.toLowerCase().includes(type.contains2.toLowerCase())
        ) {
          pushinTypeData(type, event);
        }
      } else if (
        (type.starts_with != "" &&
          event.event_name
            .toLowerCase()
            .starts_with(type.starts_with.toLowerCase())) ||
        (type.starts_with2 != "" &&
          event.event_name
            .toLowerCase()
            .starts_with(type.starts_with2.toLowerCase()))
      ) {
        pushinTypeData(type, event);
      } else if (
        (type.ends_with != "" &&
          event.event_name
            .toLowerCase()
            .ends_with(type.ends_with.toLowerCase())) ||
        (type.ends_with2 != "" &&
          event.event_name
            .toLowerCase()
            .ends_with(type.ends_with2.toLowerCase()))
      ) {
        pushinTypeData(type, event);
      }
    }
  }

  function pushinTypeData(type, event) {
    //console.log(type);
    for (let i = 0; i < typedata.length; i++) {
      const type2 = typedata[i];
      if (type.id == type2.id) {
        type2.eventarray.push(event);
        return;
      }
    }
    type.eventarray = [];
    type.eventarray.push(event);
    typedata.push(type);
  }

  for (let i = 0; i < typedata.length; i++) {
    const entry = typedata[i];
    let event_length = 0;

    for (let j = 0; j < entry.eventarray.length; j++) {
      const event = entry.eventarray[j];
      if (
        event.event_start_date > selectedDate1Start &&
        event.event_start_date < selectedDate1End
      ) {
        event_length = event_length + 1;
      }
    }
    //entry.event_length = entry.eventarray.length;
    entry.event_length = event_length;

    if (entry.eventarray.length > 0) {
      entry.eventsexist = true;
    }

    entry.first_event_date =
      entry.eventarray.length > 0 ? new Date() : undefined;
    entry.last_event_date =
      entry.eventarray.length > 0 ? new Date(0) : undefined;
    entry.event_bookings_seats_length = 0;
    entry.totalEarnings = 0;
    entry.totalEarnings2 = 0;

    for (let j = 0; j < entry.eventarray.length; j++) {
      const event = entry.eventarray[j];
      if (
        event.event_start_date > selectedDate1Start &&
        event.event_start_date < selectedDate1End
      ) {
        if (event.event_start_date < entry.first_event_date) {
          entry.first_event_date = event.event_start_date;
        }
        if (event.event_start_date > entry.last_event_date) {
          entry.last_event_date = event.event_start_date;
        }
        entry.event_bookings_seats_length =
          entry.event_bookings_seats_length + event.statistics_totalseatsbooked;
        entry.totalEarnings =
          entry.totalEarnings +
          event.statistics_totalseatsbooked * parseFloat(event.price);
      }
      if (
        event.event_start_date > selectedDate2Start &&
        event.event_start_date < selectedDate2End
      ) {
        //console.log(event.event_start_date);
        entry.totalEarnings2 =
          entry.totalEarnings2 +
          event.statistics_totalseatsbooked * parseFloat(event.price);
        //console.log("test2");
      }
    }
    console.log(entry.totalEarnings);
    console.log(entry.totalEarnings2);

    entry.difference =
      (parseInt(entry.totalEarnings - parseInt(entry.totalEarnings2)) * 100) /
      parseInt(entry.totalEarnings);
    if (entry.totalEarnings === 0) {
      entry.difference = -100;
    }
    if (entry.totalEarnings === 0 && entry.totalEarnings2 === 0) {
      entry.difference = 0;
    }
    entry.difference = entry.difference.toFixed(2);
    //   parseInt((entry.totalEarnings / entry.totalEarnings2) * 10000) / 100;
    if (isNaN(entry.difference)) {
      entry.difference = (0).toFixed(2);
    }
    console.log(entry.difference);
  }

  const actions = checkedByType
    ? [
        {
          icon: forwardRef(() => <Equalizer />),
          tooltip: "Details anzeigen",
          onClick: (e, rowData) => {
            setSelectedStatistics(rowData);
          },
        },
      ]
    : [];
  const localization = {
    header: {
      actions: "Details",
    },
  };

  const columns = checkedByType
    ? [
        { title: "Bezeichnung", field: "type_name", align: "left" },
        //{ title: 'Erste Schulung', field: 'first_event_date', type: "date" },
        //{ title: 'Letzte Schulung', field: 'last_event_date', type: "date" },
        {
          title: "Anzahl Schulungen",
          field: "event_length",
          type: "numeric",
          align: "left",
        },
        {
          title: "Gebuchte Plätze",
          field: "event_bookings_seats_length",
          type: "numeric",
          align: "left",
        },
        {
          title: "Zeitraum 1",
          field: "totalEarnings",
          type: "numeric",
          sorting: false,
          render: (rowData) => (
            <>€ {parseInt(rowData.totalEarnings).toLocaleString("de")},-</>
          ),
        },
        {
          title: "Zeitraum 2",
          field: "totalEarnings2",
          type: "numeric",
          sorting: false,
          render: (rowData) => (
            <>€ {parseInt(rowData.totalEarnings2).toLocaleString("de")},-</>
          ),
        },
        {
          title: "Abweichung in %",
          field: "difference",
          type: "numeric",
          render: (rowData) => <>{rowData.difference}%</>,
        },
      ]
    : [
        { title: "Event Name", field: "event_name" },
        {
          title: "Datum",
          field: "event_start_date",
          type: "date",
          defaultSort: "desc",
        },
        {
          title: "Gebuchte Plätze",
          field: "statistics_totalseatsbooked",
          type: "numeric",
        },
        {
          title: "Preis",
          field: "price",
          type: "numeric",
          render: (rowData) => <>€ {rowData.price.toLocaleString("de")},-</>,
        },
      ];

  const tableData = checkedByType ? typedata : data;

  console.log(tableData);

  const title = checkedByType ? "Typen" : "Schulungen";
  const customComponents = checkedByType
    ? {
        Header: (props) => {
          return (
            <CustomtableHeader
              selectedDate1Start={selectedDate1Start}
              setSelectedDate1Start={setSelectedDate1Start}
              selectedDate1End={selectedDate1End}
              setSelectedDate1End={setSelectedDate1End}
              selectedDate2Start={selectedDate2Start}
              setSelectedDate2Start={setSelectedDate2Start}
              selectedDate2End={selectedDate2End}
              setSelectedDate2End={setSelectedDate2End}
              {...props}
              availableQuickDatesQuartal={availableQuickDatesQuartal}
              availableQuickDatesYears={availableQuickDatesYears}
              data={tableData}
            ></CustomtableHeader>
          );
        },
      }
    : undefined;

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              Nach einzelnen Schulungen ordnen
              <Switch
                checked={checkedByType}
                onChange={() => {
                  setSelectedStatistics(undefined);
                  setCheckedByType(!checkedByType);
                }}
                name="Typ"
              ></Switch>
              Nach Typen ordnen
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <StatisticsGraphics
            data={typedata}
            enableSwitch
            selectedDate1Start={selectedDate1Start}
            selectedDate1End={selectedDate1End}
            selectedDate2Start={selectedDate2Start}
            selectedDate2End={selectedDate2End}
          ></StatisticsGraphics>
        </Grid>
        <Grid container item xs={12}></Grid>
        <Grid item xs={12}>
          <MaterialTable
            icons={tableIcons}
            actions={actions}
            localization={localization}
            columns={columns}
            data={tableData}
            title={title}
            components={customComponents}
          />
        </Grid>
      </Grid>
      {/* Alle Schulungen von Typ zeigen  */}
      {selectedStatistics ? (
        <StatisticsGraphics
          data={selectedStatistics}
          tableIcons={tableIcons}
          listTable
        ></StatisticsGraphics>
      ) : null}
    </div>
  );
}

export default StatisticsManager;
