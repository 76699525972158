import React from 'react'
import { CircularProgress, Grid, Paper } from '@material-ui/core';
import EventTypeCreator from './EventTypeCreator';
import EventTypeDeleter from './EventTypeDeleter';
import EventTypeList from './EventTypeList';
import EventTypeCopier from './EventTypeCopier';
import EventTypeWelcome from './EventTypeWelcome';
import EventTypeEditor from './EventTypeEditor';

function EventTypeManager(props) {
    const [view, setView] = React.useState("start");
    const [types, setTypes] = React.useState(undefined);
    const [listselect, setListSelect] = React.useState(0);

    if (types === undefined) {
        fetchTypes();
        return <CircularProgress></CircularProgress>
    }

    function fetchTypes(callback) {
        let url = global.localip + '/getTypes';
        fetch(url)
            .then(response => response.json())
            .then(result => {
                setTypes(result);
                if (callback !== undefined) {
                    callback();
                }
            })
    }

    function getSelectedType() {
        for (let i = 0; i < types.length; i++) {
            const type = types[i];
            if (type.id === listselect) {
                return type;
            }
        }
        return undefined;
    }

    let selectedType = getSelectedType();

    function viewSwitcher() {
        switch (view) {
            case "create":
                return <EventTypeCreator fetchTypes={fetchTypes} setView={setView} setListSelect={setListSelect}></EventTypeCreator>
            case "copy":
                return <EventTypeCopier selectedType={selectedType} fetchTypes={fetchTypes} setView={setView} setListSelect={setListSelect} key={listselect}></EventTypeCopier>
            case "edit":
                return <EventTypeEditor types={types} selectedType={selectedType} fetchTypes={fetchTypes} key={listselect}></EventTypeEditor>
            case "delete":
                return <EventTypeDeleter selectedType={selectedType} fetchTypes={fetchTypes} setView={setView} key={listselect}></EventTypeDeleter>
            case "start":
                return <EventTypeWelcome></EventTypeWelcome>

            default:
                return null;
        }
    }

    return (<Grid container>
        <Grid item xs={2}>
            <h3>Schulungstypisierung</h3>
            <Paper>
                <EventTypeList types={types} setListSelect={setListSelect} setView={setView}></EventTypeList>
            </Paper>
        </Grid>
        <Grid item xs={10}>
            {viewSwitcher()}
        </Grid>
    </Grid>
    )
}

export default EventTypeManager
