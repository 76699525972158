import { CircularProgress, Tab, Tabs, Box, Typography } from '@material-ui/core';
import React from 'react'
import AssignmentsManager from './AssignmentsManager/AssignmentsManager';
import MailManager from './MailManager/MailManager';
import TemplateManager from './TemplateManager/TemplateManager';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

function TemplateView() {
    const [templates, setTemplates] = React.useState(undefined);
    const [tab, setTab] = React.useState(0);

    if (templates === undefined) {
        fetchTemplates();
        return <CircularProgress></CircularProgress>
    }

    function handleTabChange(e, val) {
        setTab(val);
    }

    function fetchTemplates(callback) {
        let url = global.localip + '/getTemplates';
        fetch(url)
            .then(response => response.json())
            .then(result => {
                setTemplates(result);
                if(callback !== undefined){
                    callback();
                }
            })
    }

    return (
        <div>
            <Tabs value={tab} onChange={handleTabChange} aria-label="simple tabs example" indicatorColor="primary">
                <Tab label="Vorlageneditor" />
                <Tab label="Vorlagenzuweisungen" />
                <Tab label="Mailübersicht" />
            </Tabs>
            <TabPanel value={tab} index={0}>
                <TemplateManager templates={templates} fetchTemplates={fetchTemplates}></TemplateManager>
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <AssignmentsManager templates={templates} fetchTemplates={fetchTemplates}></AssignmentsManager>
            </TabPanel>
            <TabPanel value={tab} index={2}>
                <MailManager></MailManager>
            </TabPanel>
        </div>
    )
}



export default TemplateView;
