import { Button, CircularProgress, TextField, Grid, Modal, Dialog, Select } from '@material-ui/core';
import React from 'react'
import axios from 'axios';
import MenuItem from '@material-ui/core/MenuItem';



function TemplateEditor(props) {
    const { unlayer } = window;

    const initializeUnlayer = () =>
        unlayer.init({
            id: "unlayer",
            appearance: {
                loader: {
                    html: '<div class="custom-spinner"></div>',
                    css: '.custom-spinner { color: red; }'
                }
            }
        });

    React.useEffect(() => {
        initializeUnlayer();
        loadDesign();
    }, []);

    const [template, setTemplate] = React.useState(props.selectedTemplate);

    if (template === undefined) {
        return <CircularProgress></CircularProgress>
    }

    function loadDesign() {
        if (unlayer === undefined) {
            setTimeout(() => {
                loadDesign();
            }, 100);
        } else {
            if (template.json !== "") {
                unlayer.loadDesign(JSON.parse(template.json));
            } else {
                unlayer.loadDesign("");
            }
        }
    }

    function saveDesign() {
        unlayer.exportHtml((data) => {
            const { design, html } = data;
            template.html = html;
            template.json = JSON.stringify(design);

            axios.post(global.localip + '/updateTemplate/', {
                template: template,
            }).then(function (result) {
                props.fetchTemplates(function () {
                });
            })
        });
    }


    return <div style={{ margin: "20px" }}>
        <Grid container spacing={3} justify="space-between">
            <Grid item xs={4}>
                <TextField value={template.name} onChange={(e) => {
                    let templatec = JSON.parse(JSON.stringify(template));
                    templatec.name = e.target.value;
                    setTemplate(templatec);
                }}></TextField>
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={3}>
                <Button fullWidth variant="outlined" color="primary" onClick={saveDesign}>Template speichern</Button>
            </Grid>
        </Grid>
        <div id="unlayer" style={{ height: "75vh", marginTop: "10px" }} />;
    </div >;
}

export default TemplateEditor
