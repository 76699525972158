import React from 'react'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Chip, Select, FormControlLabel } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Status from './Status';
import EventDetails from './EventDetails';
import { Close } from '@material-ui/icons';

function Event(params) {
  var event = params.event;

  const [allPaid, setAllPaid] = React.useState(0);
  const [lecturer, setLecturer] = React.useState(event.event_lecturer);
  const [accordionExpanded, setAccordionExpanded] = React.useState(true);


  let color;
  if (event.event_id != 100000) {
    for (let i = 0; i < params.colors.length; i++) {
      const element = params.colors[i];
      if (element.id == lecturer) {
        color = element;
        break;
      }
    }
  }

  var style = {};
  if (color != undefined) {
    //style.backgroundColor = "#" + color.color;
    style.borderStyle = "solid";
    style.borderColor = "#" + color.color;
    style.borderWidth = "5px";
  } else {
    style.borderStyle = "solid";
    style.borderColor = "#273746";
    style.borderWidth = "5px";
  }

  return (<Accordion expanded={accordionExpanded} onChange={(event, value) => {
    if (params.calendar) {
      if(params.setCalendarDialog != undefined){
        params.setCalendarDialog();
      }
    } else {
      setAccordionExpanded(value);
    }
  }} style={style}>
    <AccordionSummary
      expandIcon={params.calendar == undefined ? <ExpandMoreIcon /> : <Close></Close>}>
      {
        event.event_status === 0 &&
        <p style={{ all: "unset", textDecoration: "line-through" }}>{event.event_name}</p>
      }
      {
        event.event_status !== 0 &&
        <p style={{ all: "unset" }}>{event.event_name}</p>
      }
      <Status seats={event.seats_booked} maxseats={event.event_seats} allPaid={allPaid}></Status>
      {
        event.event_status === 0 &&
        <Chip label="Schulung gelöscht" color="secondary"></Chip>
      }
      {
        event.event_status === 2 &&
        <Chip label="Privat" color="primary"></Chip>
      }
      {
        event.event_status === 5 &&
        <Chip label="Entwurf" color="primary"></Chip>
      }
      <Chip label={("0" + new Date(event.event_start_date).getDate()).slice(-2) + "." + ("0" + (new Date(event.event_start_date).getMonth() + 1)).slice(-2) + "." + new Date(event.event_start_date).getFullYear() + " " + (event.event_start_time).substring(0, 5) + " - " + ("0" + new Date(event.event_end_date).getDate()).slice(-2) + "." + ("0" + (new Date(event.event_end_date).getMonth() + 1)).slice(-2) + "." + new Date(event.event_end_date).getFullYear() + " " + (event.event_end_time).substring(0, 5) + " Uhr"}></Chip>
      <Chip label={"Kurspreis: " + event.price + " " + event.currency}></Chip>
      <Chip label={event.seats_booked + "/" + event.event_seats + " Plätze"}></Chip>
      {event.event_id != 100000 &&
        <FormControlLabel
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          control={<Select
            style={{ marginLeft: "25px" }}
            value={lecturer}
            onChange={(e) => {
              let url = global.localip + '/setEventLecturer/' + event.event_id + "/" + e.target.value;
              fetch(url)
                .then(response => response.json())
                .then(result2 => {
                  setLecturer(e.target.value);
                  if (params.changeLecturerCallBack != undefined) {
                    params.changeLecturerCallBack(e.target.value, event.event_id);
                  }
                  if (params.closeModal != undefined) {
                    params.closeModal();
                  }
                })
            }}
          >
            <MenuItem value={0}>Kein Dozent gesetzt</MenuItem>
            {params.colors.map(function (entry, i) {
              return <MenuItem value={entry.id} key={i}>{entry.surname + " " + entry.name}</MenuItem>
            })}
          </Select>}
        ></FormControlLabel>}
    </AccordionSummary>
    <AccordionDetails>
      <EventDetails changeBookingDetailsCalendarCallBack={params.changeBookingDetailsCalendarCallBack} allevents={params.allevents} rank={params.rank} event_id={event.event_id} setAllPaid={setAllPaid} bookings={event.bookingarray}></EventDetails>
    </AccordionDetails>
  </Accordion>)
}

export default Event
