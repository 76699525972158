import { Dialog, DialogTitle, Modal, Grid, Button } from '@material-ui/core';
import React from 'react'
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import StatisiticsQuickTimeSelector from './StatisiticsQuickTimeSelector';

function TableCellDateSelector(props) {
    const [open, setOpenModal] = React.useState(false);
    const [startDate, setStartDate] = React.useState(props.startDate);
    const [endDate, setEndDate] = React.useState(props.endDate);

    const options = { year: 'numeric', month: 'long', day: 'numeric' };

    const dateStartstr = props.startDate.toLocaleDateString('de-DE', options)
    const dateEndstr = props.endDate.toLocaleDateString('de-DE', options)

    function handleClose() {
        setOpenModal(false);
    }
    function handleChange() {
        props.setStartDate(startDate);
        props.setEndDate(endDate);
        setOpenModal(false);
    }

    return (
        <>
            <div onClick={() => { setOpenModal(true) }}>
                {props.children}
                <small>{dateStartstr} - </small>
                <small>{dateEndstr}</small>
            </div>
            <Dialog onClose={handleClose} open={open}>
                <DialogTitle>Zeitraum ändern</DialogTitle>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container spacing={2} xs={12} style={{ margin: "15px", marginLeft: "25px" }}>
                        <Grid item xs={6}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                label="Anfangsdatum"
                                value={startDate}
                                onChange={(date) => setStartDate(date)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                label="Enddatum"
                                value={endDate}
                                onChange={(date) => setEndDate(date)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <StatisiticsQuickTimeSelector setStartDate={setStartDate} setEndDate={setEndDate} quartals={props.availableQuickDatesQuartal} years={props.availableQuickDatesYears}></StatisiticsQuickTimeSelector>
                        </Grid>
                        <Grid item xs={5}>
                        </Grid>
                        <Grid item xs={3}>
                            <Button fullWidth onClick={handleClose} color={"secondary"} variant="outlined">Abbrechen</Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button fullWidth onClick={handleChange} color={"primary"} variant="outlined">Anwenden</Button>
                        </Grid>
                    </Grid>
                </MuiPickersUtilsProvider>
            </Dialog>
        </>
    )
}

export default TableCellDateSelector
