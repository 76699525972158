import React from 'react'
import {Grid, Paper, CircularProgress} from '@material-ui/core';
import KeyWordList from './KeyWordList';
import TemplateCopier from './TemplateCopier';
import TemplateCreater from './TemplateCreator';
import TemplateDeleter from './TemplateDeleter';
import TemplateEditor from './TemplateEditor';
import TemplateWelcome from './TemplateWelcome';
import TemplateList from './TemplateList';

function TemplateManager(props) {
    const [view, setView] = React.useState("start");
    const [listselect, setListSelect] = React.useState(0);

    function getSelectedTemplate() {
        for (let i = 0; i < props.templates.length; i++) {
            const template = props.templates[i];
            if(template.id === listselect){
                return template;
            }
        }
        return undefined;
    }

    let selectedTemplate = getSelectedTemplate();

    function viewSwitcher() {
        if(view === "create"){
            return <TemplateCreater fetchTemplates={props.fetchTemplates} setView={setView} setListSelect={setListSelect}></TemplateCreater>;
        } else if(view === "copy"){
            return <TemplateCopier selectedTemplate={selectedTemplate} fetchTemplates={props.fetchTemplates} setView={setView} setListSelect={setListSelect} key={listselect}></TemplateCopier>;
        } else if(view === "edit"){
            return <TemplateEditor selectedTemplate={selectedTemplate} fetchTemplates={props.fetchTemplates} key={listselect}></TemplateEditor>;
        } else if(view === "delete"){
            return <TemplateDeleter selectedTemplate={selectedTemplate} fetchTemplates={props.fetchTemplates} setView={setView} key={listselect}></TemplateDeleter>;
        } else if(view === "start"){
            return <TemplateWelcome></TemplateWelcome>;
        }

        return null;
    }

    return (<Grid container>
        <Grid item xs={2}>
            <h3>Vorlagen</h3>
            <Paper>
                <TemplateList setView={setView} setListSelect={setListSelect} templates={props.templates}></TemplateList>
            </Paper>
        </Grid>
        <Grid item xs={8}>
            {viewSwitcher()}
        </Grid>
        <Grid item xs={2}>
            <h3>Übersicht über Schlüsselwörter</h3>
            <Paper>
                <KeyWordList></KeyWordList>
            </Paper>
        </Grid>
    </Grid>
    )
}

export default TemplateManager
