import React from 'react'
import { List } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomListItem from './CustomListItem';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

function EventDetails(params) {
  const classes = useStyles();
  const data = params.bookings;
  const [otherchecked, setOtherChecked] = React.useState(0);
  

  if (data == undefined) {
    return (<></>);
  }
  if (data.length == 0) {
    return (<>Keine Einträge vorhanden</>)
  }

  //var seats = 0;
  var booked = 0;

  for (let i = 0; i < data.length; i++) {
    const element = data[i];
    //seats = seats + element.booking_seats;
    if (element.booking_paid == 1) {
      booked++;
    }
  }

  function increaseCheckedAmount() {
    setOtherChecked(otherchecked + 1);
  }

  //params.setSeats(seats);

  if (data.length <= booked) {
    params.setAllPaid(true);
  }

  return (
    <div className={classes.root}>
      <List>
        {data.map(function (booking, i) {
          return <div key={booking.booking_id} >
            <CustomListItem changeBookingDetailsCalendarCallBack={params.changeBookingDetailsCalendarCallBack} allevents={params.allevents} rank={params.rank} booking={booking} increaseCheckedAmount={increaseCheckedAmount}></CustomListItem>
            {i != data.length - 1 &&
              <hr style={{ border: "1px solid #EC7063" }} width="100%"></hr>}
          </div>
        })}
      </List>
    </div>
  )
}

export default EventDetails
