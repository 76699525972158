import React from 'react';
import axios from 'axios';
import { Button, Grid, Paper, TextField } from '@material-ui/core';


function TemplateCreater(props) {
    const [name, setName] = React.useState(undefined);


    function addTemplate() {
        if(name != undefined && name.length != 0){
            axios.post(global.localip + '/addTemplate/', {
                template: { name: name },
            }).then(function (result) {
                props.fetchTemplates(function () {
                    props.setListSelect(result.data.insertId);
                    props.setView("edit");
                });
            })
        }
    }

    return (
        <div>
            <Grid container>
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                    <Paper style={{padding: "10px"}}>
                        <div >
                            <h3>Hier kannst du ein neues Template erstellen</h3>
                            <TextField value={name} placeholder={"Vorlage benennen"} onChange={(e) => {setName(e.target.value)}}></TextField>
                            <Button variant="outlined" onClick={addTemplate}>Erstellen</Button>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={1}></Grid>
            </Grid>
        </div>
    )
}

export default TemplateCreater;
