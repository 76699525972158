import React from 'react'
import {List, ListItem, ListItemText, Paper, Collapse, Drawer, IconButton} from '@material-ui/core';
import {Close, ExpandLess, ExpandMore} from '@material-ui/icons';

function KeyWordList() {
    const [openDrawer, setOpenDrawer] = React.useState(true);
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);

    return <>
            <List>
                <ListItem button onClick={() => setOpen1(!open1)}>
                    <ListItemText primary="Buchungsbestätigung"></ListItemText>
                    {open1 ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open1} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem>
                            <ListItemText primary="#_EVENTNAME - Name des Events" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="#_STARTDATE - 'Starttag' des Events" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="#_STARTTIME - 'Startzeit' des Events" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="#_ENDTIME - 'Endzeit' des Events" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="#_RESPSPACES - Anzahl gebuchte Plätze" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="#_LINKFILES - Link zu Dateien aus Vorlagenzuweisung" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="#_LINKMEET - Link zu Meeting aus Vorlagenzuweisung" />
                        </ListItem>
                    </List>
                </Collapse>
                <ListItem button onClick={() => setOpen2(!open2)}>
                    <ListItemText primary="Warteliste"></ListItemText>
                    {open2 ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open2} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem>
                            <ListItemText primary="#_PREVEVENTNAME - Name des Events aus dem der Teilnehmer verschoben wurde" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="#_PREVSTARTDATE - 'Starttag' des Events aus dem der Teilnehmer verschoben wurde" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="#_PREVSTARTTIME - 'Startzeit' des Events aus dem der Teilnehmer verschoben wurde" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="#_PREVENDTIME - 'Endzeit' des Events aus dem der Teilnehmer verschoben wurde" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="#_PREVRESPSPACES - Anzahl gebuchte Plätze" />
                        </ListItem>
                    </List>
                </Collapse>
                <ListItem button onClick={() => setOpen3(!open3)} disabled>
                    <ListItemText primary="Zertifikate"></ListItemText>
                    {open3 ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open3} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem>
                            <ListItemText primary="Hier kommen noch Schlüsselwörter..." />
                        </ListItem>
                    </List>
                </Collapse>
            </List>
    </>
}

export default KeyWordList;
